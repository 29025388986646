import React from 'react';
import { useHistory } from 'react-router-dom';
import { Card, CardContent } from '@material-ui/core';
import DataTable from '../../../shared/components/DataTable';
import { Appointment } from '../../model';
import AppointmentCommentsList from '../AppointmentCommentsList';
import { adminAppointmentColumns } from '../../constants';

type AdminAppointmentsListProps = {
  records: Appointment[];
  recordsLoading: boolean;
  totalRecords?: number;
  handlePageChange: (page: number) => void;
  resetPagination: boolean;
  handleColumnSort: (column: Record<string, string>, sortDirection: string) => Promise<void>;
  selectedRows: any;
  setSelectedRows: (selectedRows: any) => void;
};

function AdminAppointmentsList(props: AdminAppointmentsListProps) {
  const {
    records = [],
    recordsLoading = false,
    totalRecords = records?.length || 0,
    handlePageChange,
    resetPagination,
    handleColumnSort,
    selectedRows,
    setSelectedRows,
  } = props;

  const history = useHistory();

  const ExpandedCommentsList = ({ data }) => <AppointmentCommentsList mostRecentComment={data.most_recent_comment} />;

  return (
    <Card>
      <CardContent>
        <DataTable
          columns={adminAppointmentColumns}
          noHeader
          data={records}
          progressPending={recordsLoading}
          striped
          onRowClicked={(row: Appointment) => history.push(`/students/${row.student_id}/appointments/${row.id}`)}
          highlightOnHover
          pointerOnHover
          pagination
          paginationServer
          paginationPerPage={25}
          paginationRowsPerPageOptions={[25]}
          paginationTotalRows={totalRecords}
          onChangePage={handlePageChange}
          paginationResetDefaultPage={resetPagination}
          expandableRows
          expandableRowExpanded={(appointment: Appointment) => appointment.most_recent_comment !== null}
          expandableRowsComponent={<ExpandedCommentsList data />}
          sortServer
          onSort={handleColumnSort}
          selectableRows
          selectableRowsHighlight
          selectedRows={selectedRows}
          onSelectedRowsChange={({ selectedRows }) => setSelectedRows(selectedRows)}
        />
      </CardContent>
    </Card>
  );
}

export default AdminAppointmentsList;
