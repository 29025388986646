import React, { useEffect } from 'react';
import { Button, Card, CardContent, Grid, Typography, makeStyles } from '@material-ui/core';
import DuplicateAppointmentQuarters from './DuplicateAppointmentQuarters';
import ManageRenewals from './ManageRenewals';
import { axios } from '../../shared/singletons';
import { DuplicateAppointmentsContext } from '../contexts/DuplicateAppointmentsContext';
import { AppointmentsContext } from '../../appointments/contexts/AppointmentsContext';
import DeleteModal from '../../shared/components/modal/DeleteModal';
import LoadingSpinner from '../../shared/components/LoadingSpinner';

const useStyles = makeStyles((theme) => ({
  listItemIcon: {
    minWidth: '24px',
  },
  modalText: {
    fontSize: '20px',
    marginLeft: '20%',
    marginBottom: '20px',
    width: '60%',

    [theme.breakpoints.down('sm')]: {
      marginLeft: '2%',
      width: '96%',
    },
  },
}));

const DuplicateAppointmentsFormContainer: React.FC = () => {
  const classes = useStyles();
  const [isDestroyModalOpen, setDestroyModalOpen] = React.useState<boolean>(false);
  const [destroyService, setDestroyService] = React.useState<any>({ service_type: '' });
  const [deleteText, setDeleteText] = React.useState<string>('Delete');
  const [appointmentChoicesFetched, setAppointmentChoicesFetched] = React.useState<boolean>(false);

  const {
    // setDuplicateAppointmentsModalOpen,
    // setDuplicateAppointmentsByQuarterModalOpen,
    // setDuplicateAppointmentsByWeekModalOpen,
    setDuplicateAppointmentsByRenewalDateModalOpen,
    appointmentScheduler,
    setFormValues,
    fetchRecord,
    setServiceForAppointmentGenerator,
    setYearlongGeneratorModalOpen,
    submittingDuplication,
  } = React.useContext(DuplicateAppointmentsContext);

  const { fetchAppointmentChoices } = React.useContext(AppointmentsContext);

  useEffect(() => {
    if (!appointmentChoicesFetched) {
      fetchAppointmentChoices(appointmentScheduler.id);
      setAppointmentChoicesFetched(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointmentChoicesFetched]);

  const handleDestroyFutureAppointments = () => {
    setDeleteText('Deleting...');

    axios
      .delete('yearlong_appointment_generators/destroy', {
        params: { referral_service_id: destroyService.id, user_id: appointmentScheduler.owner_id },
      })
      .then(() => {
        fetchRecord(appointmentScheduler.owner_id, appointmentScheduler.id);
        setDestroyModalOpen(false);
        setDeleteText('Delete');
      });
  };

  return !appointmentScheduler.id ? (
    <Grid item>
      <Typography variant="h2" component="h2">
        Loading...
      </Typography>
    </Grid>
  ) : (
    <Grid item>
      {appointmentScheduler?.renewals?.length > 0 && (
        <Card>
          <CardContent>
            <ManageRenewals renewals={appointmentScheduler?.renewals} appointmentLoading={false} />
          </CardContent>
        </Card>
      )}
      <br />
      {appointmentScheduler?.services?.filter((service) => service?.id).length === 0 &&
        appointmentScheduler?.renewals?.length === 0 && (
          <Card>
            <CardContent>
              <Typography align="center" className={classes.modalText} variant="h5">
                This student has no active services or renewals, would you like to discharge this student? This action
                cannot be undone.
              </Typography>
              <Grid item style={{ textAlign: 'center' }}>
                <a
                  href={`/students/${appointmentScheduler.id}/discharge`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary MuiButton-disableElevation"
                  style={{ backgroundColor: '#F44F64', textAlign: 'center' }}
                >
                  <span className="MuiButton-label">Discharge Student</span>
                  <span className="MuiTouchRipple-root"></span>
                </a>
              </Grid>
            </CardContent>
          </Card>
        )}
      {appointmentScheduler?.services?.map((service: any, idx) => (
        <div key={service?.id}>
          <DeleteModal
            isOpen={isDestroyModalOpen}
            openStatusChanged={setDestroyModalOpen}
            onDelete={handleDestroyFutureAppointments}
            confirmLabel={deleteText}
          >
            <Typography align="center" className={classes.modalText} variant="h5">
              Are you sure you want to delete all appointments for the next school year for this{' '}
              {destroyService.service_type} service?
            </Typography>
          </DeleteModal>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Card>
                <CardContent>
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <Typography variant="h2" component="h2" style={{ textDecoration: 'underline' }}>
                        <a
                          rel="noopener noreferrer"
                          href={`/students/${appointmentScheduler?.id}/services/${service?.id}/appointments`}
                          target="_blank"
                        >
                          Service {idx + 1} of {appointmentScheduler.services.length} ~ {service.service_type}
                        </a>
                      </Typography>
                      <Typography>
                        <strong>Frequency:</strong> {service.frequency}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Button
                        variant="contained"
                        disableElevation
                        color="primary"
                        style={{ float: 'right', backgroundColor: '#F44F64' }}
                        onClick={() => {
                          setDestroyModalOpen(true);
                          setDestroyService(service);
                        }}
                      >
                        Delete Future Appointments
                      </Button>
                      <a
                        href={`/students/${appointmentScheduler.id}/discharge`}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ float: 'right', marginRight: 7 }}
                        className="MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedDefault MuiButton-disableElevation"
                      >
                        <span className="MuiButton-label">Discharge Service</span>
                        <span className="MuiTouchRipple-root"></span>
                      </a>
                    </Grid>
                  </Grid>
                  <br />
                  {submittingDuplication ? (
                    <LoadingSpinner />
                  ) : (
                    <>
                      <DuplicateAppointmentQuarters
                        title="Last Year Quarters"
                        quarters={service?.current_quarters}
                        appointmentLoading={false}
                      />

                      <DuplicateAppointmentQuarters
                        title="Upcoming Year Quarters"
                        quarters={service?.next_quarters}
                        appointmentLoading={false}
                      />
                    </>
                  )}
                  {/* TODO: Set FormValues in a useEffect instead when clicking the button */}
                  {['IEP Meeting Participation', '504 Meeting Participation'].includes(service.service_type) ? (
                    <Grid container spacing={1}>
                      <Grid item>
                        <Button
                          variant="contained"
                          disableElevation
                          color="primary"
                          onClick={() => {
                            setFormValues({
                              referral_service_id: service.id,
                              frequency: service.frequency,
                              frequency_count: service.frequency_count,
                              frequency_count_units: service.frequency_count_units,
                              frequency_interval: service.frequency_interval,
                              weekday: service.initial_weekday,
                              start_time: service.initial_start_time,
                              primary_service_id: service.primary_service_id,
                              primary_service_name: service.primary_service_name,
                              primary_service_renewal_id: service.primary_service_renewal_id,
                              primary_service_renewal_date: service.primary_service_renewal_date,
                              location_id: service.initial_location_id,
                              owner_id: service.initial_owner_id,
                            });
                            setDuplicateAppointmentsByRenewalDateModalOpen(true);
                          }}
                        >
                          Duplicate Using Renewal Date
                        </Button>
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid container spacing={1} style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <Grid item>
                        <Button
                          variant="contained"
                          disableElevation
                          color="secondary"
                          onClick={() => {
                            setServiceForAppointmentGenerator(service);
                            setYearlongGeneratorModalOpen(true);
                          }}
                        >
                          Yearlong Appointment Generator
                        </Button>
                      </Grid>
                    </Grid>
                  )}
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <br />
        </div>
      ))}
    </Grid>
  );
};

export default DuplicateAppointmentsFormContainer;
