import React, { FunctionComponent } from 'react';
import StepperModal from '../../shared/components/modal/StepperModal';
import StudentDetailsForm from './StudentDetailsForm';
import StudentAddressForm from './StudentAddressForm';
import { studentFormStore, studentRecordStore } from '../../shared/singletons';
import { useStoreObservable } from '../../shared/state/useStoreObservable.hook';
import { _translateEach } from '../../shared/utils/translation.utils';

const StudentDetailsFormModal: FunctionComponent = () => {
  const { formStepperIndex, detailsModalOpen, formValues } = useStoreObservable(studentFormStore);
  const { student } = useStoreObservable(studentRecordStore);

  const t = _translateEach({
    updateTitle: 'students.formModal.updateTitle',
    cancel: 'students.formModal.cancel',
  });

  const reloadStudent = () => {
    studentRecordStore.fetchRecord(student.id);
    studentFormStore.setState({ detailsModalOpen: false });
  };

  const handleNextClick = (currentIndex: number): void => {
    if (currentIndex === 0) {
      studentFormStore.createOrUpdateStudent(formValues, student.id);
    } else if (currentIndex === 1) {
      studentFormStore.updateAddress(formValues, student.id, reloadStudent);
    }
  };

  const stepperModalContent = () => {
    switch (formStepperIndex) {
      case 0:
        return <StudentDetailsForm />;
      case 1:
        return <StudentAddressForm />;
      default:
        return <></>;
    }
  };

  return (
    <StepperModal
      currentStepIndex={formStepperIndex}
      totalSteps={2}
      isOpen={detailsModalOpen}
      title={t.updateTitle}
      openStatusChanged={(isOpen) => studentFormStore.setState({ detailsModalOpen: isOpen })}
      buttonLabelsByIndex={[
        { cancel: t.cancel, confirm: 'Update Student' },
        { cancel: t.cancel, confirm: 'Update Address' },
      ]}
      previousClicked={() => studentFormStore.setStepperIndex(formStepperIndex - 1)}
      nextClicked={() => handleNextClick(formStepperIndex)}
    >
      {stepperModalContent()}
    </StepperModal>
  );
};

export default StudentDetailsFormModal;
