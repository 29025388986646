import React from 'react';
import { format } from 'date-fns';
import { Box, Drawer, Link, List, ListItem, Typography, makeStyles } from '@material-ui/core';
import AppointmentIcon from '@material-ui/icons/DateRange';
import AddIcon from '@material-ui/icons/AddOutlined';
import DashboardIcon from '@material-ui/icons/DashboardOutlined';
import ReferralIcon from '@material-ui/icons/ListAlt';
import StudentIcon from '@material-ui/icons/SchoolOutlined';
import RenewalIcon from '@material-ui/icons/AvTimerOutlined';
import UserIcon from '@material-ui/icons/PersonOutlineOutlined';
import SchoolIcon from '@material-ui/icons/Business';
import LogoutIcon from '@material-ui/icons/PowerSettingsNew';
import Badge from '@mui/material/Badge';
import FeedbackIcon from '@material-ui/icons/Feedback';
import { UserContext } from '../../auth/contexts/userContext';
import { Urls } from '../constants/urls';

const drawerWidth = 100;
const useStyles = makeStyles((_theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    position: 'fixed',
    top: '65px',
    display: 'table',
    height: 'calc(100% - 65px)',
  },
  list: {
    display: 'table-cell',
    verticalAlign: 'middle',
  },
  link: {
    width: '100%',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  listItem: {
    textAlign: 'center',
    padding: '10px',
  },
  logoutItem: {
    textAlign: 'center',
    padding: '10px',
    marginTop: '15px',
  },
}));

const Sidebar: React.FC = () => {
  const classes = useStyles();
  const { logout, currentUserHasRole, currentUserHasAnyRole, user } = React.useContext(UserContext);

  const today = () => {
    return format(new Date(), 'yyyy-MM-dd');
  };

  return (
    <Box display={{ xs: 'none', sm: 'none', md: 'block' }}>
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor="left"
      >
        <List className={classes.list}>
          {currentUserHasRole('school_admin') && (
            <ListItem button className={classes.listItem} key="new-referral">
              <Link className={classes.link} href="/referral_students/new">
                <AddIcon style={{ width: '1.2em', height: '1.2em' }} />
                <Typography variant="subtitle2">New Referral</Typography>
              </Link>
            </ListItem>
          )}

          <ListItem button className={classes.listItem} key="dashboard">
            <Link className={classes.link} href={Urls.DashboardRoute}>
              <DashboardIcon />
              <Typography variant="subtitle2">Dashboard</Typography>
            </Link>
          </ListItem>
          <ListItem
            button
            className={classes.listItem}
            key="students"
            onClick={() =>
              sessionStorage.setItem(
                'studentState',
                JSON.stringify({
                  filterValues: {
                    'ransack[discharged_eq]': 'false',
                    'appointment_ransack[start_time_gteq]': today(),
                    'appointment_ransack[start_time_lteq]': today(),
                  },
                }),
              )
            }
          >
            <Link className={classes.link} href={Urls.StudentsRoute} data-cy="students-sidebar">
              <StudentIcon />
              <Typography variant="subtitle2">Students</Typography>
            </Link>
          </ListItem>
          {!currentUserHasRole('school_admin') && (
            <ListItem
              button
              className={classes.listItem}
              key="appointments"
              onClick={() =>
                sessionStorage.setItem(
                  'appointmentState',
                  JSON.stringify({
                    filterValues: {
                      'ransack[start_time_gteq]': today(),
                      'ransack[start_time_lteq]': today(),
                    },
                  }),
                )
              }
            >
              <Link className={classes.link} href={Urls.AppointmentsRoute}>
                <AppointmentIcon />
                <Typography variant="subtitle2">Appointments</Typography>
              </Link>
            </ListItem>
          )}
          <ListItem
            button
            className={classes.listItem}
            key="services"
            onClick={() =>
              sessionStorage.setItem(
                'serviceState',
                JSON.stringify({
                  filterValues: {
                    'ransack[status_eq]': 'active',
                    'appointment_ransack[start_time_gteq]': today(),
                    'appointment_ransack[start_time_lteq]': today(),
                  },
                }),
              )
            }
          >
            <Link className={classes.link} href="/referrals">
              <ReferralIcon />
              <Typography variant="subtitle2">Referrals</Typography>
            </Link>
          </ListItem>
          {currentUserHasAnyRole([
            'system_owner',
            'admin',
            'service_provider',
            'supervisor',
            'services_coordinator',
          ]) && (
            <ListItem
              button
              className={classes.listItem}
              key="renewals"
              onClick={() =>
                sessionStorage.setItem(
                  'renewalState',
                  JSON.stringify({
                    filterValues: {
                      'ransack[active_eq]': 'true',
                    },
                  }),
                )
              }
            >
              <Link className={classes.link} href={Urls.RenewalsRoute}>
                <RenewalIcon />
                <Typography variant="subtitle2">Renewals</Typography>
              </Link>
            </ListItem>
          )}
          <Box>
            {currentUserHasAnyRole(['admin', 'supervisor']) && (
              <ListItem
                button
                className={classes.listItem}
                key="users"
                onClick={() =>
                  sessionStorage.setItem(
                    'userState',
                    JSON.stringify({
                      filterValues: {
                        'ransack[inactivated_at_null]': '1',
                      },
                    }),
                  )
                }
              >
                <Link className={classes.link} href={Urls.UsersRoute}>
                  <UserIcon />
                  <Typography variant="subtitle2">Users</Typography>
                </Link>
              </ListItem>
            )}
          </Box>
          <>
            {currentUserHasAnyRole(['system_owner', 'admin', 'accountant']) && (
              <ListItem
                button
                className={classes.listItem}
                key="schools"
                onClick={() =>
                  sessionStorage.setItem(
                    'schoolState',
                    JSON.stringify({
                      filterValues: {},
                    }),
                  )
                }
              >
                <Link className={classes.link} href={Urls.SchoolsRoute}>
                  <SchoolIcon />
                  <Typography variant="subtitle2">Schools</Typography>
                </Link>
              </ListItem>
            )}
          </>
          <>
            {currentUserHasAnyRole(['admin', 'service_provider', 'supervisor', 'system_owner', 'supervisor']) && (
              <ListItem
                button
                className={classes.listItem}
                key="tickets"
                onClick={() =>
                  sessionStorage.setItem(
                    'schoolState',
                    JSON.stringify({
                      filterValues: {},
                    }),
                  )
                }
              >
                <Link className={classes.link} href="/user_tickets">
                  <Badge color="error" badgeContent={user.ticket_count}>
                    <FeedbackIcon />
                  </Badge>
                  <Typography variant="subtitle2">Tickets</Typography>
                </Link>
              </ListItem>
            )}
          </>
          <ListItem button className={classes.logoutItem} key="sign_out">
            <Link className={classes.link} onClick={logout}>
              <LogoutIcon />
              <Typography variant="subtitle2">Sign Out</Typography>
            </Link>
          </ListItem>
        </List>
      </Drawer>
    </Box>
  );
};

export default Sidebar;
