import React, { Dispatch, SetStateAction, useState, useEffect } from 'react';
import styled from 'styled-components';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { Button, FormControl, Grid, InputLabel, MenuItem, TextField, Select } from '@material-ui/core';
import FilterListIcon from '@material-ui/icons/FilterList';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { SelectOption, SelectElementEvent } from '../common.model';
import { UserContext } from '../../auth/contexts/userContext';

const useStyles = makeStyles(() =>
  createStyles({
    inputRoot: {
      backgroundColor: 'white',
    },
    formControl: {
      width: '100%',
      background: 'white',
      height: '38px',
    },
  }),
);

const StyledSelectFormControl = styled(FormControl)`
  .MuiSelect-select {
    padding: 10px;
  }
  .MuiInputLabel-root {
    margin-top: -6px;
  }
  .MuiInputLabel-shrink {
    margin-top: 0;
  }
`;

export interface FilterProps {
  label: string;
  selector: string;
  type: string;
  advanced?: boolean;
  options?: SelectOption[];
  multiple?: boolean;
}

interface ContextFilterBarProps {
  filters?: FilterProps[];
  advancedFilters?: FilterProps[];
  onFilter: (filterParams: string) => void;
  filterValues?: Record<string, string>;
  setFilterValues?: Dispatch<SetStateAction<Record<string, string>>>;
  advancedFiltersOpen?: boolean;
  filterSingleRecord?: boolean;
  onExport?: () => void;
}

const ContextFilterBar: React.FC<ContextFilterBarProps> = (props) => {
  const {
    filters,
    advancedFilters,
    onFilter,
    filterValues,
    setFilterValues,
    advancedFiltersOpen = false,
    onExport,
  } = props;
  const { currentUserHasRole, currentUserHasAnyRole } = React.useContext(UserContext);

  const [open, setOpen] = useState<boolean>(advancedFiltersOpen);
  const [temporaryValues, setTemporaryValues] = useState<Record<string, any>>(filterValues);

  const classes = useStyles();

  const handleClick = () => setOpen(!open);

  useEffect(() => {
    setTemporaryValues(filterValues);
  }, [filterValues]);

  const handleFilterInputChange = (event: SelectElementEvent, field: string) => {
    // Store filter values only as component state until we hit apply button
    setTemporaryValues({ ...temporaryValues, [field]: event.target.value });
  };

  const handleFilter = () => {
    setFilterValues(temporaryValues);

    const filterParams = Object?.keys(temporaryValues)?.reduce(
      (params, key, _index: number) => `${params}${key}=${temporaryValues[key]}&` || '',
      '',
    );
    onFilter(filterParams);
  };

  const getStatusStyles = (status: string, activeStatuses: string[] | string) => {
    if (activeStatuses?.length > 0) {
      return {
        fontWeight: activeStatuses?.indexOf(status) === -1 ? 400 : 700,
      };
    }

    return {
      fontWeight: status === 'Any' ? 700 : 400,
    };
  };

  return (
    <Grid container spacing={1} alignItems="center">
      <Grid item xs={12}>
        <Grid container spacing={1} alignItems="center">
          {filters.map((filter) => (
            <Grid item xs={12} md={2} key={filter.label}>
              {filter.options ? (
                <StyledSelectFormControl variant="outlined" className={classes.formControl}>
                  <InputLabel htmlFor="outlined-age-native-simple">{filter.label}</InputLabel>
                  {filter.multiple ? (
                    <Select
                      variant="outlined"
                      placeholder={filter.label}
                      label={filter.label}
                      type={filter.type}
                      multiple={filter.multiple}
                      value={(temporaryValues[filter.selector] as string[])?.filter((x) => x !== ' ') || [' ']}
                      renderValue={(selected: string[]) =>
                        temporaryValues[filter.selector] ? selected.join(', ') : 'Any'
                      }
                      onChange={(e: SelectElementEvent) => handleFilterInputChange(e, filter.selector)}
                    >
                      <MenuItem value=" " style={getStatusStyles('Any', temporaryValues[filter.selector])}>
                        Any
                      </MenuItem>
                      {filter.options.map((option) => (
                        <MenuItem
                          value={option.value}
                          key={option.value}
                          style={getStatusStyles(option.value, temporaryValues[filter.selector])}
                        >
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  ) : (
                    <Select
                      variant="outlined"
                      placeholder={filter.label}
                      label={filter.label}
                      type={filter.type}
                      value={temporaryValues[filter.selector] || ' '}
                      onChange={(e: SelectElementEvent) => handleFilterInputChange(e, filter.selector)}
                    >
                      <MenuItem value=" " key="">
                        Any
                      </MenuItem>
                      {filter.options.map((option) => (
                        <MenuItem value={option.value} key={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  )}
                </StyledSelectFormControl>
              ) : (
                <TextField
                  variant="outlined"
                  size="small"
                  fullWidth
                  label={filter.type === 'date' ? filter.label : null}
                  type={filter.type}
                  value={temporaryValues[filter.selector] || ''}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  placeholder={filter.label}
                  onBlur={(e) => handleFilterInputChange(e, filter.selector)}
                  onChange={(e: SelectElementEvent) => handleFilterInputChange(e, filter.selector)}
                  onKeyPress={(e) => {
                    if (e.charCode === 13) {
                      e.preventDefault();
                      handleFilter();
                    }
                  }}
                  InputProps={{
                    className: classes.inputRoot,
                  }}
                />
              )}
            </Grid>
          ))}
          <Grid item>
            <Button variant="contained" color="primary" disableElevation onClick={() => handleFilter()}>
              Apply
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="default"
              disableElevation
              onClick={() => {
                setTemporaryValues({});
              }}
            >
              Clear All
            </Button>
          </Grid>
          {onExport && currentUserHasRole('system_owner') && (
            <Grid item>
              <Button variant="contained" color="default" disableElevation onClick={onExport}>
                Export As CSV
              </Button>
            </Grid>
          )}
        </Grid>

        <Grid item xs={12}>
          <form noValidate autoComplete="off">
            <Grid container spacing={1} alignItems="center">
              {currentUserHasAnyRole(['admin', 'supervisor', 'system_owner']) &&
                advancedFilters &&
                advancedFilters.length > 0 && (
                  <Grid item>
                    <Button
                      onClick={handleClick}
                      color={open ? 'secondary' : 'primary'}
                      startIcon={open ? <VisibilityOffIcon /> : <FilterListIcon />}
                    >
                      {open ? 'Hide Advanced Filters' : 'Show Advanced Filters'}
                    </Button>
                  </Grid>
                )}
            </Grid>
          </form>
        </Grid>
        {open && (
          <Grid container spacing={1} alignItems="center">
            {advancedFilters?.map((filter) => (
              <Grid item xs={12} md={2} key={filter.label}>
                {filter.options ? (
                  <StyledSelectFormControl variant="outlined" className={classes.formControl}>
                    <InputLabel htmlFor="outlined-age-native-simple">{filter.label}</InputLabel>
                    {filter.multiple ? (
                      <Select
                        variant="outlined"
                        placeholder={filter.label}
                        label={filter.label}
                        type={filter.type}
                        multiple={filter.multiple}
                        value={(temporaryValues[filter.selector] as string[])?.filter((x) => x !== ' ') || [' ']}
                        renderValue={(selected: string[]) =>
                          temporaryValues[filter.selector] ? selected.join(', ') : 'Any'
                        }
                        onChange={(e: SelectElementEvent) => handleFilterInputChange(e, filter.selector)}
                      >
                        <MenuItem value=" " style={getStatusStyles('Any', temporaryValues[filter.selector])}>
                          Any
                        </MenuItem>
                        {filter.options.map((option) => (
                          <MenuItem
                            value={option.value}
                            key={option.value}
                            style={getStatusStyles(option.value, temporaryValues[filter.selector])}
                          >
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                    ) : (
                      <Select
                        variant="outlined"
                        placeholder={filter.label}
                        label={filter.label}
                        type={filter.type}
                        value={temporaryValues[filter.selector] || ' '}
                        onChange={(e: SelectElementEvent) => handleFilterInputChange(e, filter.selector)}
                      >
                        <MenuItem value=" " key="">
                          Any
                        </MenuItem>
                        {filter.options.map((option) => (
                          <MenuItem value={option.value} key={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  </StyledSelectFormControl>
                ) : (
                  <TextField
                    variant="outlined"
                    size="small"
                    fullWidth
                    label={filter.type === 'date' ? filter.label : null}
                    type={filter.type}
                    value={temporaryValues[filter.selector] || ''}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    placeholder={filter.label}
                    onBlur={(e) => handleFilterInputChange(e, filter.selector)}
                    onChange={(e: SelectElementEvent) => handleFilterInputChange(e, filter.selector)}
                    onKeyPress={(e) => {
                      if (e.charCode === 13) {
                        e.preventDefault();
                        handleFilter();
                      }
                    }}
                    InputProps={{
                      className: classes.inputRoot,
                    }}
                  />
                )}
              </Grid>
            ))}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default ContextFilterBar;
