import React, { FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Card, CardContent, Typography, Grid } from '@material-ui/core';
import InformationField from '../../shared/components/InformationField';
import LoadingSpinner from '../../shared/components/LoadingSpinner';
import StudentDetailsFormModal from './StudentDetailsFormModal';
import { Student } from '../model';
import { UserContext } from '../../auth/contexts/userContext';

interface StudentInformationProps {
  student: Student;
  studentLoading: boolean;
}

const StudentInformation: FunctionComponent<StudentInformationProps> = (props) => {
  const { student, studentLoading } = props;
  const { currentUserHasRole } = React.useContext(UserContext);
  const history = useHistory();

  return studentLoading ? (
    <LoadingSpinner />
  ) : (
    <Card>
      <StudentDetailsFormModal />
      <CardContent>
        <Grid container justify="space-between">
          <Grid item>
            <Typography variant="h2" component="h2">
              Student Information
            </Typography>
          </Grid>
          {currentUserHasRole('admin') && (
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={() => history.push(`/students/${student.id}/edit_details`)}
              >
                Edit Student
              </Button>
            </Grid>
          )}
        </Grid>
        <br />
        <Grid container spacing={3}>
          <Grid item xs={6} md={4}>
            <InformationField label="Status" value={student?.status === 'true' ? 'INACTIVE' : 'ACTIVE'} />
          </Grid>
          <Grid item xs={6} md={4}>
            <InformationField label="Name" value={student?.student_name} />
          </Grid>
          <Grid item xs={6} md={4}>
            <InformationField label="Timeline ID" value={student?.slug} />
          </Grid>
          <Grid item xs={6} md={4}>
            <InformationField label="School-Issued ID" value={student?.school_issued_id} />
          </Grid>
          <Grid item xs={6} md={4}>
            <InformationField label="Referral Date" value={student?.referral_date} />
          </Grid>
          <Grid item xs={6} md={4}>
            <InformationField label="School" value={student?.school} />
          </Grid>
          <Grid item xs={6} md={4}>
            <InformationField label="Preferred Location" value={student?.location} />
          </Grid>
          <Grid item xs={6} md={4}>
            <InformationField label="Teacher" value={student?.teacher} />
          </Grid>
          <Grid item xs={6} md={4}>
            <InformationField label="Grade" value={student?.grade} />
          </Grid>
          <Grid item xs={6} md={4}>
            <InformationField label="Room Number" value={student?.room_number} />
          </Grid>
          <Grid item xs={6} md={4}>
            <InformationField label="Address" value={student?.address} />
          </Grid>
          <Grid item xs={6} md={4}>
            <InformationField label="Email" value={student?.email} />
          </Grid>
          <Grid item xs={6} md={4}>
            <InformationField label="Date of Birth" value={student?.formatted_birthdate} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default StudentInformation;
