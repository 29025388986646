/* eslint-disable react-hooks/exhaustive-deps */

import React, { FunctionComponent, useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Grid, Typography } from '@material-ui/core';
import ConfirmCancelModal from '../../../shared/components/modal/ConfirmCancelModal';
import TextInput from '../../../shared/components/form/TextInput';
import SelectInput from '../../../shared/components/form/SelectInput';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { ListingServicesContext } from '../contexts/ListingServicesContext';
import { allAppointmentStatusChoices } from '../../../appointments/constants';
import { ListingServiceFormValues } from '../model';
import { axios } from '../../../shared/singletons';
import { useStyles } from '../../../shared/style/siteWideStyles';

const EditListingServiceModal: FunctionComponent = () => {
  const history = useHistory();
  const [choicesFetched, setChoicesFetched] = useState(false);
  const [selectedStatuses, setSelectedStatuses] = useState([]);
  const [formValues, setFormValues] = useState<ListingServiceFormValues>({});

  const classes = useStyles();

  const { fetchChoices, serviceCategoryChoices, editModalOpen, setEditModalOpen, listingService } = useContext(
    ListingServicesContext,
  );

  useEffect(() => {
    if (!choicesFetched) {
      setChoicesFetched(true);
      fetchChoices();
    }
  }, [choicesFetched, setChoicesFetched, fetchChoices]);

  const handleFormSubmit = () => {
    axios
      .put(`/listing_services/${listingService.id}.json`, { listing_service: formValues })
      .then(() => history.push('/listing_services'));
  };

  const handleCheckboxChange = (e) => {
    const value = e.target.value;
    let newValues = [];

    if (e.target.checked) {
      newValues = [...selectedStatuses, value];
    } else {
      newValues = selectedStatuses.filter((item) => item !== value);
    }

    setSelectedStatuses(newValues);
    setFormValues({ ...formValues, possible_statuses: newValues });
  };

  useEffect(() => {
    setFormValues(listingService);
    setSelectedStatuses(listingService?.possible_statuses);
  }, [listingService]);

  return (
    <ConfirmCancelModal
      title="Edit Listing Service"
      cancelLabel="Cancel"
      confirmLabel="Update Listing Service"
      width="768px"
      hasCancelButton
      hasConfirmButton
      isOpen={editModalOpen}
      onConfirm={handleFormSubmit}
      openStatusChanged={(isOpen) => setEditModalOpen(isOpen)}
    >
      <Box p={2}>
        <Typography variant="h2">Listing Service Details</Typography>
        <Grid container spacing={3}>
          <Grid item xs={10}>
            <TextInput
              styleOverrides={classes.textInput}
              label="Listing Service Name"
              value={formValues?.name}
              valueChanged={(value: string) => setFormValues({ ...formValues, name: value })}
            />
            <SelectInput
              styleOverrides={classes.textInput}
              type="text"
              label="Status"
              choices={[
                { label: 'Active', value: 'active' },
                { label: 'Inactive', value: 'inactive' },
              ]}
              value={formValues?.status}
              valueChanged={(value) => setFormValues({ ...formValues, status: value })}
            />
            <SelectInput
              styleOverrides={classes.textInput}
              type="text"
              label="Editable Units"
              choices={[
                { label: 'Yes', value: 'true' },
                { label: 'No', value: 'false' },
              ]}
              value={formValues?.editable}
              valueChanged={(value) => setFormValues({ ...formValues, editable: value })}
            />
            <TextInput
              styleOverrides={classes.textInput}
              type="text"
              label="Maximum Units"
              value={formValues?.maximum_units}
              valueChanged={(value) => setFormValues({ ...formValues, maximum_units: value })}
            />
            <SelectInput
              styleOverrides={classes.textInput}
              type="text"
              label="Service Category"
              choices={serviceCategoryChoices}
              value={formValues?.service_category_id}
              valueChanged={(value) => setFormValues({ ...formValues, service_category_id: value })}
            />
            <SelectInput
              styleOverrides={classes.textInput}
              type="text"
              label="Goal Required"
              choices={[
                { label: 'Yes', value: 'true' },
                { label: 'No', value: 'false' },
              ]}
              value={formValues?.goal_required}
              valueChanged={(value) => setFormValues({ ...formValues, goal_required: value })}
            />
            <SelectInput
              styleOverrides={classes.textInput}
              type="text"
              label="Renewal Required"
              choices={[
                { label: 'Yes', value: 'true' },
                { label: 'No', value: 'false' },
              ]}
              value={formValues?.renewal_required}
              valueChanged={(value) => setFormValues({ ...formValues, renewal_required: value })}
            />
            <br />
            <Typography variant="h3">Allow Statuses</Typography>
            {allAppointmentStatusChoices.map((stat) => (
              <div key={stat.value}>
                <FormControlLabel
                  control={
                    <Checkbox
                      value={stat.value}
                      checked={formValues?.possible_statuses?.includes(stat.value)}
                      onChange={handleCheckboxChange}
                      name="possible_statuses"
                      color="primary"
                      disabled={stat.disabled}
                    />
                  }
                  label={stat.label}
                />{' '}
                &emsp;
              </div>
            ))}
          </Grid>
        </Grid>
        <br />
      </Box>
    </ConfirmCancelModal>
  );
};

export default EditListingServiceModal;
