import React, { FunctionComponent, ChangeEvent, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Grid, Typography } from '@material-ui/core';
import TabLabels from '../../shared/components/TabLabels';
import TabLabel from '../../shared/components/TabLabel';
import TabPanel from '../../shared/components/TabPanel';
import LoadingSpinner from '../../shared/components/LoadingSpinner';
import StudentDetailsTab from './StudentDetailsTab';
import { UserContext } from '../../auth/contexts/userContext';
import { Student } from '../model';
import { appointmentBaseStore } from '../../shared/singletons';
import StudentCustomActions from './StudentCustomActions';
import StudentAppointmentsTab from './StudentAppointmentsTab';
import StudentServicesTab from './StudentServicesTab';
import StudentGoalsTab from './StudentGoalsTab';
import StudentRenewalsTab from './StudentRenewalsTab';

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

interface StudentDetailProps {
  student: Student;
  studentLoading: boolean;
  tab?: string;
}

const profileObject = { value: 'profile', label: 'Profile', component: StudentDetailsTab };
const servicesObject = { value: 'services', label: 'Services', component: StudentServicesTab };
const appointmentsObject = { value: 'appointments', label: 'Appointments', component: StudentAppointmentsTab };
const renewalsObject = { value: 'renewals', label: 'Renewals', component: StudentRenewalsTab };
const goalsObject = { value: 'goals', label: 'Goals', component: StudentGoalsTab };
const actionsObject = { value: 'actions', label: 'Actions', component: StudentCustomActions };

// Comment to deploy

const StudentDetail: FunctionComponent<StudentDetailProps> = (props) => {
  const history = useHistory();

  useEffect(() => {
    appointmentBaseStore.fetchChoices();
  }, []);

  const { student, studentLoading, tab = null } = props;
  const [value, setValue] = React.useState(0);
  const { currentUserHasRole, currentUserHasAnyRole } = React.useContext(UserContext);

  let objectsByRole = [];

  if (currentUserHasRole('admin')) {
    objectsByRole = [profileObject, servicesObject, appointmentsObject, renewalsObject, goalsObject, actionsObject];
  } else if (currentUserHasAnyRole(['accountant', 'services_coordinator'])) {
    objectsByRole = [profileObject, servicesObject, appointmentsObject];
  } else if (currentUserHasRole('supervisor')) {
    objectsByRole = [profileObject, servicesObject, appointmentsObject, renewalsObject, goalsObject];
  } else if (currentUserHasRole('service_provider')) {
    objectsByRole = [profileObject, servicesObject, appointmentsObject, renewalsObject, goalsObject];
  } else if (currentUserHasRole('school_admin')) {
    objectsByRole = [profileObject];
  }

  const handleChange = (_event: ChangeEvent, newValue: number) => {
    setValue(newValue);
    window.history.pushState('', '', `/students/${student.id}/${objectsByRole[newValue]?.value}`);
  };

  useEffect(() => {
    if (tab) {
      setValue(objectsByRole.findIndex((obj) => obj.value === tab));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab]);

  return studentLoading ? (
    <LoadingSpinner />
  ) : (
    <div>
      <Grid justify="space-between" container spacing={3}>
        <Grid item xs={8}>
          <Typography variant="h1" component="h1">
            {`${student.first_name} ${student.last_name}`}
          </Typography>
        </Grid>
        <Grid item>
          {currentUserHasRole('admin') && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => history.push(`/students/${student.id}/add_services`)}
            >
              Create New Service +
            </Button>
          )}
        </Grid>
      </Grid>
      <div>
        <TabLabels variant="scrollable" value={value} onChange={handleChange} aria-label="student tabs">
          {objectsByRole.map((obj, index) => (
            <TabLabel key={index} label={obj.label} other={a11yProps(index)} />
          ))}
        </TabLabels>
        {objectsByRole.map((obj, index) => {
          const Component = obj.component;
          return (
            <TabPanel key={index} value={value} index={index}>
              <Component student={student} studentLoading={studentLoading} />
            </TabPanel>
          );
        })}
      </div>
    </div>
  );
};

export default StudentDetail;
