import React, { FunctionComponent, ChangeEvent, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Grid, Typography } from '@material-ui/core';
import LoadingSpinner from '../../shared/components/LoadingSpinner';
import TabLabels from '../../shared/components/TabLabels';
import TabLabel from '../../shared/components/TabLabel';
import TabPanel from '../../shared/components/TabPanel';
import ServiceDetailsTab from './ServiceDetailsTab';
import AddAppointmentsFormModal from '../../appointments/components/AddAppointmentsFormModal';
import { Service } from '../model';
import Breadcrumbs, { BreadcrumbLink } from '../../shared/components/Breadcrumbs';
import { UserContext } from '../../auth/contexts/userContext';
import ServiceCustomActions from './ServiceCustomActions';
import { serviceRecordStore, appointmentBaseStore } from '../../shared/singletons';
import { ENVIRONMENT } from '../../../config/env';
import ServiceGoalsTab from './ServiceGoalsTab';
import ServiceAppointmentsTab from './ServiceAppointmentsTab';

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

interface ServiceDetailProps {
  service: Service;
  serviceLoading: boolean;
  tab?: string;
}

const ServiceDetail: FunctionComponent<ServiceDetailProps> = (props) => {
  const { service, serviceLoading, tab } = props;
  const history = useHistory();

  const detailsObject = { value: 'details', label: 'Details', component: ServiceDetailsTab };
  const appointmentsObject = { value: 'appointments', label: 'Appointments', component: ServiceAppointmentsTab };
  const goalsObject = { value: 'goals', label: 'Goals', component: ServiceGoalsTab };
  const actionsObject = { value: 'actions', label: 'Actions', component: ServiceCustomActions };

  const [value, setValue] = React.useState<number>(0);
  const { currentUserHasRole, currentUserHasAnyRole } = React.useContext(UserContext);
  const [addAppointmentsModalOpen, setAddAppointmentsModalOpen] = React.useState(false);

  let objectsByRole = [];

  if (currentUserHasRole('admin')) {
    objectsByRole = [detailsObject, appointmentsObject, goalsObject, actionsObject];
  } else if (currentUserHasRole('supervisor')) {
    objectsByRole = [detailsObject, appointmentsObject, goalsObject];
  } else if (currentUserHasRole('service_provider')) {
    objectsByRole = [detailsObject, appointmentsObject, goalsObject];
  } else if (currentUserHasAnyRole(['accountant', 'services_coordinator'])) {
    objectsByRole = [detailsObject, appointmentsObject];
  }

  // Remove goals tab if goal_required is false
  if (!service.goal_required) {
    objectsByRole = objectsByRole.filter((obj) => obj.value !== 'goals');
  }

  useEffect(() => {
    appointmentBaseStore.fetchChoices();
  }, []);

  const links: BreadcrumbLink[] = [
    {
      href: `/students/${service.student_id}`,
      text: `${service?.student?.first_name} ${service?.student?.last_name}`,
    },
    {
      href: `/students/${service.student_id}/services`,
      text: 'SERVICES',
    },
    {
      href: null,
      text: service.service_type,
    },
  ];

  const handleChange = (_event: ChangeEvent, newValue: number) => {
    setValue(newValue);
    window.history.pushState(
      '',
      '',
      `/students/${service.student_id}/services/${service.id}/${objectsByRole[newValue]?.value}`,
    );
  };

  useEffect(() => {
    if (tab) {
      setValue(objectsByRole.findIndex((obj) => obj.value === tab));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab]);

  return serviceLoading && !currentUserHasRole('developer') ? (
    <LoadingSpinner />
  ) : (
    <div>
      <AddAppointmentsFormModal
        service={service}
        isOpen={addAppointmentsModalOpen}
        setOpen={setAddAppointmentsModalOpen}
        onSuccess={() => {
          serviceRecordStore.fetchRecord(service.student_id, service.id);
          setAddAppointmentsModalOpen(false);
        }}
      />
      <Grid justify="space-between" container spacing={3}>
        <Grid item>
          <Typography variant="h1" component="h1">
            {service.service_type}
          </Typography>
          <Breadcrumbs links={links} />
          <br />
        </Grid>
        <Grid item>
          {currentUserHasRole('admin') && (
            <>
              <Button variant="contained" color="primary" onClick={() => setAddAppointmentsModalOpen(true)}>
                Add Appointments
              </Button>
              &emsp;
              {ENVIRONMENT === 'staging' && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => history.push(`/services/${service?.id}/appointments/yearlong-generator`)}
                >
                  Yearlong Appointment Generator
                </Button>
              )}
            </>
          )}
        </Grid>
      </Grid>
      <div>
        <TabLabels variant="scrollable" value={value} onChange={handleChange} aria-label="student tabs">
          {objectsByRole.map((obj, index) => (
            <TabLabel key={index} label={obj.label} other={a11yProps(index)} />
          ))}
        </TabLabels>
        {objectsByRole.map((obj, index) => {
          const Component = obj.component;
          return (
            <TabPanel key={index} value={value} index={index}>
              <Component service={service} serviceLoading={serviceLoading} />
            </TabPanel>
          );
        })}
      </div>
    </div>
  );
};

export default ServiceDetail;
