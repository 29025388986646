/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, FunctionComponent, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Grid, Typography } from '@material-ui/core';
import InformationField from '../../shared/components/InformationField';
import { Service } from '../../services/model';
import { axios } from '../../shared/singletons';
import YearlongAppointmentGeneratorForm from '../components/YearlongAppointmentGeneratorForm';
import YearlongAppointmentPreviewCalendar from '../components/YearlongAppointmentPreviewCalendar';
import LoadingSpinner from '../../shared/components/LoadingSpinner';
import { AppointmentsContext } from '../contexts/AppointmentsContext';
import { SchoolsContext } from '../../schools/contexts/SchoolsContext';

export type YearlongAppointmentGeneratorPageProps = Record<string, unknown>;

type AddAppointmentParams = {
  serviceId: string;
};

const YearlongAppointmentGeneratorPage: FunctionComponent<YearlongAppointmentGeneratorPageProps> = () => {
  const { serviceId } = useParams<AddAppointmentParams>();

  const [service, setService] = useState<Service>({});

  const {
    appointmentsLoading,
    setAppointmentsLoading,
    appointmentChoices,
    fetchAppointmentChoices,
    formShowing,
  } = useContext(AppointmentsContext);

  const { school, fetchSchool } = useContext(SchoolsContext);

  const fetchService = async () => {
    setAppointmentsLoading(true);
    const response = await axios.get(`referral_services/${serviceId}`);
    setService(response?.data?.result);

    const payer_id = response?.data?.result?.student?.payer_id;
    const student_id = response?.data?.result?.student_id;

    if (payer_id) {
      await fetchSchool(payer_id);
    }

    if (student_id) {
      await fetchAppointmentChoices(student_id);
    }

    setAppointmentsLoading(false);
  };

  useEffect(() => {
    fetchService();
  }, [serviceId]);

  return (
    <>
      <Typography variant="h1">Yearlong Appointment Generator</Typography>
      {appointmentsLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <Grid container spacing={6}>
            <Grid item>
              <InformationField label="Student" value={service?.student_name} />
            </Grid>
            <Grid item>
              <InformationField label="Timeline ID" value={service?.student_slug} />
            </Grid>
            <Grid item>
              <InformationField label="Service Type" value={service?.service_type} />
            </Grid>
          </Grid>
          <br />
          {/* Should we retrieve the appointmentChoices within the form components instead of sending them as a prop here? */}
          {formShowing ? (
            <YearlongAppointmentGeneratorForm
              service={service}
              school={school}
              appointmentChoices={appointmentChoices}
            />
          ) : (
            <YearlongAppointmentPreviewCalendar service={service} />
          )}
        </>
      )}
    </>
  );
};

export default YearlongAppointmentGeneratorPage;
