import React, { useState, useContext } from 'react';
import { CSVLink } from 'react-csv';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import { Appointment } from '../model';
import { appointmentRecordStore } from '../../shared/singletons';
import { FilterProps } from '../../shared/components/FilterBar';
import { BaseRecordStore } from '../../shared/state/baseRecord.store';
import { UserContext } from '../../auth/contexts/userContext';
import AppointmentFilterBar from './AppointmentFilterBar';
import { AppointmentsContext } from '../contexts/AppointmentsContext';
import AppointmentBulkStatusChangeModal from './AppointmentBulkStatusChangeModal';
import EditCommentModal from './EditCommentModal';
import ServiceProviderAppointmentsList from './AppointmentListsByRole/ServiceProviderAppointmentsList';
import AdminAppointmentsList from './AppointmentListsByRole/AdminAppointmentsList';
import AccountantAppointmentsList from './AppointmentListsByRole/AccountantAppointmentsList';
import SupervisorAppointmentsList from './AppointmentListsByRole/SupervisorAppointmentsList';
import AppointmentQuickStatusDeleteModal from './AppointmentQuickStatusDeleteModal';
import DeleteAppointmentConfirmModal from './DeleteAppointmentConfirmModal';
import DeleteFutureAppointmentsConfirmModal from './DeleteFutureAppointmentsConfirmModal';

interface AppointmentsListProps {
  records: Appointment[];
  recordsLoading: boolean;
  totalRecords?: number;

  // Props for when AppointmentsList is nested inside other page
  showBulkActions?: boolean;
  paginationServer?: boolean;
  onPageChange?: (page: number) => void;
  showFilterBar?: boolean;
  filters?: FilterProps[];
  filterStore?: BaseRecordStore<unknown>;
  onFilter?: () => void;
  onSort?: (sort_params: string) => void;
  selectableRows?: boolean;
}

const AppointmentsList: React.FC<AppointmentsListProps> = (props) => {
  const {
    records = [],
    recordsLoading = false,
    totalRecords = records?.length || 0,

    // Props for when AppointmentsList is nested inside other page
    showBulkActions = false,
    showFilterBar = false,
    paginationServer = true,
    onPageChange,
    filters,
    filterStore,
    onFilter,
    onSort,
  } = props;

  const { currentUserHasRole, currentUserHasAnyRole } = useContext(UserContext);
  const { downloadableAppointments, downloadableAppointmentsLoading, fetchDownloadableAppointments } = useContext(
    AppointmentsContext,
  );
  const [resetPagination, setResetPagination] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [bulkUpdateModalOpen, setBulkUpdateModalOpen] = useState(false);

  const handlePageChange = (page: number) => {
    if (selectedRows.length > 0) {
      alert(
        'At this time, you can only select appointments from a single page. We apologize for the inconvenience, we have reset your selection.',
      );
      setResetPagination(true);
    } else {
      if (onPageChange) {
        onPageChange(page);
      } else if (paginationServer) {
        const filterParams = appointmentRecordStore.formattedFilterParams();
        appointmentRecordStore.fetchRecords(filterParams, page, 25);
      }
    }
  };

  const handleColumnSort = async (column: Record<string, string>, sortDirection: string) => {
    const columnNamesToRansack = {
      student_name: 'patient_last_name',
      school_abbreviation: 'payer_abbr',
      service_type: 'service_name',
      location: 'location_name',
      formatted_schedule_date: 'start_time',
      formatted_start_time: 'start_time',
      formatted_end_time: 'end_time',
      owner_last_name_first_name: 'user_last_name',
      formatted_original_date: 'original_date',
      last_modified: 'updated_at',
      units: 'in_units',
      status: 'status',
    };

    if (onSort) {
      onSort(`sort=${columnNamesToRansack[column.selector]} ${sortDirection}`);
    } else {
      appointmentRecordStore.setState({
        filterValues: {
          ...appointmentRecordStore.getState().filterValues,
          sort: `${columnNamesToRansack[column.selector]} ${sortDirection}`,
        },
      });

      const filterParams = appointmentRecordStore.formattedFilterParams();
      appointmentRecordStore.fetchRecords(filterParams, 1, 25);
    }
  };

  const handleBulkUpdate = () => {
    setBulkUpdateModalOpen(true);
  };

  return (
    <Box>
      <EditCommentModal
        onSuccess={() => {
          appointmentRecordStore.fetchRecords(appointmentRecordStore.formattedFilterParams(), 1, 25);
          fetchDownloadableAppointments(appointmentRecordStore.formattedFilterParams());
        }}
      />
      <DeleteAppointmentConfirmModal />
      <DeleteFutureAppointmentsConfirmModal />
      <AppointmentBulkStatusChangeModal
        bulkUpdateModalOpen={bulkUpdateModalOpen}
        setBulkUpdateModalOpen={setBulkUpdateModalOpen}
        selectedRows={selectedRows}
        onSuccess={() => appointmentRecordStore.fetchRecords(appointmentRecordStore.formattedFilterParams(), 1, 25)}
      />
      <AppointmentQuickStatusDeleteModal />
      <Grid justify="space-between" container spacing={3}>
        <Grid item>
          <Typography variant="h1" component="h1">
            Appointments
          </Typography>
        </Grid>
        {showBulkActions && (
          <Grid item>
            {currentUserHasRole('admin') && (
              <Button
                variant="contained"
                color="primary"
                onClick={handleBulkUpdate}
                disabled={selectedRows.length === 0}
              >
                Bulk Update {selectedRows.length > 0 ? selectedRows.length : ''}{' '}
                {selectedRows.length === 1 ? 'Appointment' : 'Appointments'}
              </Button>
            )}
            {currentUserHasAnyRole(['admin', 'accountant', 'services_coordinator']) && (
              <CSVLink
                data={downloadableAppointments}
                filename="appointments.csv"
                className={`MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedPrimary
                    ${downloadableAppointmentsLoading ? 'Mui-disabled' : ''}
                  `}
                style={{ marginLeft: '8px', backgroundColor: '#424E7A', color: '#fff' }}
              >
                Download Filtered Appointments
              </CSVLink>
            )}
          </Grid>
        )}
        {showFilterBar && (
          <AppointmentFilterBar
            filters={filters}
            filterStore={filterStore}
            onFilter={onFilter}
            resetPagination={resetPagination}
            setResetPagination={setResetPagination}
            showMultipleStatusSelect={false}
          />
        )}
      </Grid>
      <br />
      {currentUserHasRole('service_provider') && (
        <ServiceProviderAppointmentsList
          records={records}
          totalRecords={totalRecords}
          recordsLoading={recordsLoading}
          handlePageChange={handlePageChange}
          handleColumnSort={handleColumnSort}
        />
      )}
      {currentUserHasRole('supervisor') && (
        <SupervisorAppointmentsList
          records={records}
          totalRecords={totalRecords}
          recordsLoading={recordsLoading}
          handlePageChange={handlePageChange}
          handleColumnSort={handleColumnSort}
        />
      )}
      {currentUserHasRole('admin') && (
        <AdminAppointmentsList
          records={records}
          totalRecords={totalRecords}
          recordsLoading={recordsLoading}
          handlePageChange={handlePageChange}
          handleColumnSort={handleColumnSort}
          resetPagination={resetPagination}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
        />
      )}
      {currentUserHasAnyRole(['accountant', 'services_coordinator']) && (
        <AccountantAppointmentsList
          records={records}
          totalRecords={totalRecords}
          recordsLoading={recordsLoading}
          handlePageChange={handlePageChange}
          handleColumnSort={handleColumnSort}
        />
      )}
    </Box>
  );
};

export default AppointmentsList;
