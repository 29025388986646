import React from 'react';
import { studentRecordStore } from '../../shared/singletons';
import { Student } from '../model';
import GoalsList from '../../goals/components/GoalsList';
import AppointmentNotesList from '../../appointmentNotes/components/AppointmentNotesList';
import { Grid } from '@material-ui/core';
import ServiceSubjectiveNotes from '../../sessionNotes/components/ServiceSubjectiveNotes';

type StudentGoalsTabProps = {
  student: Student;
  studentLoading: boolean;
};

function StudentGoalsTab(props: StudentGoalsTabProps) {
  const { student, studentLoading } = props;

  return (
    <div>
      <GoalsList
        goals={student?.goals}
        studentId={student.id}
        isLoading={studentLoading}
        showCreateButton={false}
        afterAction={() => studentRecordStore.fetchRecord(student.id)}
      />
      <br />
      <AppointmentNotesList appointmentNotes={student?.appointment_notes} />
      <br />
      <Grid item xs={12}>
        {student?.subjective_notes?.length > 0 && (
          <ServiceSubjectiveNotes title="Subjective Notes" serviceSubjectiveNotes={student?.service_subjective_notes} />
        )}
      </Grid>
    </div>
  );
}

export default StudentGoalsTab;
