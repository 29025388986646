import { axios, alertStore } from '../shared/singletons';
import { BaseRecordStore } from '../shared/state/baseRecord.store';
import { Student } from './model';
import { API_ENDPOINT } from '../../config/env';
import { today } from '../shared/utils/date.utils';

export class StudentState {
  records: Student[];
  totalRecords: number;
  student: Student;
  recordsLoading: boolean;
  studentLoading: boolean;
  appointmentsLoading: boolean;
  filteredRecords: Student[];
  filterValues: Record<string, string>;

  static create(props: Partial<StudentState>): StudentState {
    const defaults: StudentState = {
      records: [],
      totalRecords: 0,
      student: {},
      recordsLoading: false,
      studentLoading: false,
      appointmentsLoading: false,
      filteredRecords: null,
      filterValues: {
        'ransack[discharged_eq]': 'false',
        'appointment_ransack[start_time_gteq]': today(),
        'appointment_ransack[start_time_lteq]': today(),
      },
    };
    return Object.assign(new StudentState(), defaults, props || {});
  }
}

export class StudentRecordStore extends BaseRecordStore<StudentState> {
  constructor() {
    super(StudentState.create(JSON.parse(sessionStorage.getItem('studentState')) || {}));
  }

  public fetchRecords(params = this.formattedFilterParams(), page = 1, per = 10): void {
    this.setState({ recordsLoading: true });
    this.saveStudentFilters();

    axios(`${API_ENDPOINT}/patients.json?${params}page=${page}&per=${per}`)
      .then((result) => {
        this.setState({ totalRecords: result?.data?.total_records });
        return result?.data?.result ?? [];
      })
      .then((records) => {
        this.setState({ records, recordsLoading: false });
      })
      .catch(() => {
        this.setState({ recordsLoading: false });
      });
  }

  public fetchRecord(studentId: string, params = this.formattedFilterParams()): void {
    this.setState({ studentLoading: true });

    const formattedStudentId = studentId || this?.getState()?.student?.id;

    axios(`${API_ENDPOINT}/patients/${formattedStudentId}.json?${params}`)
      .then((result) => result?.data?.result ?? [])
      .then((student) => {
        this.setState({ student, studentLoading: false });
      })
      .catch(() => {
        this.setState({ studentLoading: false });
      });
  }

  // This should be generalized instead of creating a fetchStudentServices method
  public fetchStudentAppointments(studentId: string, params = this.formattedFilterParams(), page = 1, per = 25): void {
    this.setState({ appointmentsLoading: true });

    axios(`${API_ENDPOINT}/patients/${studentId}.json?${params}&appointment_page=${page}&appointment_per=${per}`)
      .then((result) => result?.data?.result ?? [])
      .then((student) => {
        this.setState({ student, appointmentsLoading: false });
      })
      .catch(() => {
        this.setState({ appointmentsLoading: true });
        alertStore.alertError('students.alert.unknown.error');
      });
  }

  public resetFilterValues(): void {
    this.setState({ filterValues: {} });
  }

  public formattedFilterParams(): string {
    const { filterValues } = this.getState();

    return Object?.keys(filterValues)?.reduce(
      (params, key, _index: number) => `${params}${key}=${filterValues[key]}&` || '',
      '',
    );
  }

  public saveStudentFilters(): void {
    const { filterValues } = this.getState();
    const savedStudentState = {
      filterValues: {
        'ransack[last_name_cont]': filterValues['ransack[last_name_cont]'],
        'ransack[slug_cont]': filterValues['ransack[slug_cont]'],
        'ransack[payer_id_eq]': filterValues['ransack[payer_id_eq]'],
        'ransack[created_by_eq]': filterValues['ransack[created_by_eq]'],
        'ransack[discharged_eq]': filterValues['ransack[discharged_eq]'],

        'appointment_ransack[start_time_gteq]': today(),
        'appointment_ransack[start_time_lteq]': today(),
      },
      loadListPageRecords: true,
    };

    sessionStorage.setItem('studentState', JSON.stringify(savedStudentState));
  }
}
