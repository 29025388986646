import React from 'react';
import { useHistory } from 'react-router-dom';
import { Card, CardContent } from '@material-ui/core';
import DataTable from '../../../shared/components/DataTable';
import { Appointment } from '../../model';
import AppointmentCommentsList from '../AppointmentCommentsList';
import { accountantAppointmentColumns } from '../../constants';

type AccountantAppointmentsListProps = {
  records: Appointment[];
  recordsLoading: boolean;
  totalRecords?: number;
  handlePageChange: (page: number) => void;
  handleColumnSort: (column: Record<string, string>, sortDirection: string) => Promise<void>;
};

function AccountantAppointmentsList(props: AccountantAppointmentsListProps) {
  const {
    records = [],
    recordsLoading = false,
    totalRecords = records?.length || 0,
    handlePageChange,
    handleColumnSort,
  } = props;

  const history = useHistory();

  const ExpandedCommentsList = ({ data }) => <AppointmentCommentsList mostRecentComment={data.most_recent_comment} />;

  return (
    <Card>
      <CardContent>
        <DataTable
          columns={accountantAppointmentColumns}
          noHeader
          data={records}
          progressPending={recordsLoading}
          striped
          onRowClicked={(row: Appointment) => history.push(`/students/${row.student_id}/appointments/${row.id}`)}
          highlightOnHover
          pointerOnHover
          pagination
          paginationServer
          paginationPerPage={25}
          paginationRowsPerPageOptions={[25]}
          paginationTotalRows={totalRecords}
          onChangePage={handlePageChange}
          paginationResetDefaultPage={false}
          expandableRows
          expandableRowExpanded={(appointment: Appointment) => appointment.most_recent_comment !== null}
          expandableRowsComponent={<ExpandedCommentsList data />}
          sortServer
          onSort={handleColumnSort}
          selectableRows
          selectableRowsHighlight
        />
      </CardContent>
    </Card>
  );
}

export default AccountantAppointmentsList;
