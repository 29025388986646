import React, { FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Card, CardContent, Typography, Grid } from '@material-ui/core';
import InformationField from '../../shared/components/InformationField';
import LoadingSpinner from '../../shared/components/LoadingSpinner';
import { Student } from '../model';
import { _translateEach } from '../../shared/utils/translation.utils';
import { UserContext } from '../../auth/contexts/userContext';

interface StudentContactInformationProps {
  student: Student;
  studentLoading: boolean;
}

const StudentContactInformation: FunctionComponent<StudentContactInformationProps> = (props) => {
  const { student, studentLoading } = props;
  const { currentUserHasRole } = React.useContext(UserContext);
  const history = useHistory();

  const t = _translateEach({
    title: 'students.emergencyContacts.title',
    primaryGuardian: 'students.emergencyContacts.primaryGuardian',
    secondaryGuardian: 'students.emergencyContacts.secondaryGuardian',
    primaryPhone: 'students.emergencyContacts.guardianContactPrimary',
    secondaryPhone: 'students.emergencyContacts.guardianContactSecondary',
    alternatePhone: 'students.emergencyContacts.guardianContactAlternate',
    email: 'students.emergencyContacts.email',
  });

  return studentLoading ? (
    <LoadingSpinner />
  ) : (
    <Card>
      <CardContent>
        <Grid container justify="space-between">
          <Grid item>
            <Typography variant="h2" component="h2">
              Contact Information
            </Typography>
          </Grid>
          <Grid item>
            {currentUserHasRole('admin') && (
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => history.push(`/students/${student.id}/edit_contact_info/update`)}
                >
                  Edit Contacts
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
        <br />
        {student?.primary_guardian || student?.secondary_guardian || student?.emergency_name ? (
          <></>
        ) : (
          <p>No emergency contact information for this student.</p>
        )}
        {student?.primary_guardian && (
          <>
            <Grid container spacing={3}>
              <Grid item xs={6} md={4}>
                <InformationField label="Primary Guardian" value={student?.primary_guardian} />
              </Grid>
              <Grid item xs={6} md={4}>
                <Grid container spacing={3}>
                  {student?.primary_guardian_phone_contact1 && (
                    <Grid item xs={12}>
                      <InformationField label={t.primaryPhone} value={student?.primary_guardian_phone_contact1} />
                    </Grid>
                  )}
                  {student?.primary_guardian_phone_contact2 && (
                    <Grid item xs={12}>
                      <InformationField label={t.secondaryPhone} value={student?.primary_guardian_phone_contact2} />
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={6} md={4}>
                <Grid container spacing={3}>
                  {student?.primary_guardian_phone_contact3 && (
                    <Grid item xs={12}>
                      <InformationField label={t.alternatePhone} value={student?.primary_guardian_phone_contact3} />
                    </Grid>
                  )}
                  {student?.primary_guardian_email && (
                    <Grid item xs={12}>
                      <InformationField label={t.email} value={student?.primary_guardian_email} />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <hr />
          </>
        )}
        {student?.secondary_guardian && (
          <>
            <Grid container spacing={3}>
              <Grid item xs={6} md={4}>
                <InformationField label="Secondary Guardian" value={student?.secondary_guardian} />
              </Grid>
              <Grid item xs={6} md={4}>
                <Grid container spacing={3}>
                  {student?.secondary_guardian_phone_contact1 && (
                    <Grid item xs={12}>
                      <InformationField label={t.primaryPhone} value={student?.secondary_guardian_phone_contact1} />
                    </Grid>
                  )}
                  {student?.secondary_guardian_phone_contact2 && (
                    <Grid item xs={12}>
                      <InformationField label={t.secondaryPhone} value={student?.secondary_guardian_phone_contact2} />
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={6} md={4}>
                <Grid container spacing={3}>
                  {student?.secondary_guardian_phone_contact3 && (
                    <Grid item xs={12}>
                      <InformationField label={t.alternatePhone} value={student?.secondary_guardian_phone_contact3} />
                    </Grid>
                  )}
                  {student?.secondary_guardian_email && (
                    <Grid item xs={12}>
                      <InformationField label={t.email} value={student?.secondary_guardian_email} />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <hr />
          </>
        )}
      </CardContent>
    </Card>
  );
};

export default StudentContactInformation;
