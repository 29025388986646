/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext, FunctionComponent } from 'react';
import { Grid, Typography, FormGroup, FormControlLabel, Box, Radio, Button } from '@material-ui/core';
import { Service } from '../../services/model';
import { School } from '../../schools/model';
import SelectInput from '../../shared/components/form/SelectInput';
import TextInput from '../../shared/components/form/TextInput';
import { SelectOption } from '../../shared/common.model';
import { stringToDayOfWeek } from '../../shared/utils/date.utils';
import { useStyles } from '../../shared/style/siteWideStyles';
import { AppointmentsContext } from '../contexts/AppointmentsContext';

type YAGFormUserVersionProps = {
  service?: Service;
  school?: School;
  locationId?: string;
  ownerId?: string;
};

const minutesPerQuarterChoices = [
  { label: '45', value: '45' },
  { label: '75', value: '75' },
  { label: '90', value: '90' },
  { label: '135', value: '135' },
  { label: '255', value: '255' },
  { label: '465', value: '465' },
  { label: '675', value: '675' },
  { label: '885', value: '885' },
];

const appointmentsPerWeekChoices = [
  { label: '1', value: '1' },
  { label: '2', value: '2' },
];

const YAGFormUserVersion: FunctionComponent<YAGFormUserVersionProps> = (props) => {
  const { service, school, locationId, ownerId } = props;
  const classes = useStyles();

  const [startDateLabel, setStartDateLabel] = useState('');
  const [disablePreviewButton, setDisablePreviewButton] = useState(true);
  const [ownerToDisplay, setOwnerToDisplay] = useState<SelectOption | undefined>();
  const [countLabel, setCountLabel] = useState('Minutes Per Quarter');
  const [countChoices, setCountChoices] = useState(minutesPerQuarterChoices);

  const {
    yearlongGeneratorFormValues,
    setYearlongGeneratorFormValues,
    formShowing,
    setFormShowing,
    appointmentChoices,
  } = useContext(AppointmentsContext);

  const schoolHasAllDates = () => {
    const attributesToCheck = ['q1_sd', 'q2_sd', 'q3_sd', 'q4_sd', 'q1_ed', 'q2_ed', 'q3_ed', 'q4_ed'];
    return attributesToCheck.every((attr) => school?.[attr]);
  };

  // These default values should probably be established in the context when yearlongGeneratorFormValues is set?

  useEffect(() => {
    const newFormValues = {
      ...yearlongGeneratorFormValues,
      year: 'next',
      start_time: '00:00',
      frequency_count: '0',
      frequency_count_units: 'minutes',
      frequency_interval: 'quarter',
      type_of_schedule: 'minutes_per_quarter',
    };

    if (service?.id) {
      newFormValues.referral_service_id = service.id;
    }

    if (service?.frequency_count) {
      newFormValues.frequency_count = service.frequency_count;
      newFormValues.frequency_count_units = service.frequency_count_units;
      newFormValues.frequency_interval = service.frequency_interval;
    }

    if (school?.q1_sd) {
      newFormValues.schedule_date = school?.q1_sd;
    }

    if (locationId) {
      newFormValues.location_id = locationId;
    }

    if (ownerId) {
      const ownerObject = appointmentChoices?.all_owners?.find((owner) => owner.value === ownerId);
      setOwnerToDisplay(ownerObject);
      newFormValues.owner = ownerId.toString();
    }

    setYearlongGeneratorFormValues(newFormValues);
  }, [school, service, locationId, ownerId]);

  useEffect(() => {
    if (
      yearlongGeneratorFormValues?.frequency_count !== '0' &&
      yearlongGeneratorFormValues?.schedule_date &&
      yearlongGeneratorFormValues?.start_time &&
      yearlongGeneratorFormValues?.location_id &&
      schoolHasAllDates()
    ) {
      setDisablePreviewButton(false);
    } else {
      setDisablePreviewButton(true);
    }
  }, [yearlongGeneratorFormValues]);

  const setTargetType = (value: string) => {
    if (value === 'minutes_per_quarter') {
      setYearlongGeneratorFormValues({
        ...yearlongGeneratorFormValues,
        type_of_schedule: value,
        frequency_count: '0',
        frequency_count_units: 'minutes',
        frequency_interval: 'quarter',
      });
      setCountLabel('Minutes Per Quarter');
      setCountChoices(minutesPerQuarterChoices);
    } else if (value === 'appointments_per_year') {
      setYearlongGeneratorFormValues({
        ...yearlongGeneratorFormValues,
        type_of_schedule: value,
        frequency_count: '36',
        frequency_count_units: 'appointments',
        frequency_interval: 'year',
      });
    } else if (value === 'appointments_per_week') {
      setYearlongGeneratorFormValues({
        ...yearlongGeneratorFormValues,
        type_of_schedule: value,
        frequency_count: '0',
        frequency_count_units: 'appointments',
        frequency_interval: 'week',
      });
      setCountLabel('Appointments Per Week');
      setCountChoices(appointmentsPerWeekChoices);
    }
  };

  return (
    <>
      <Grid container>
        {!schoolHasAllDates() && (
          <Grid item xs={12}>
            <Typography variant="h3" component="h3">
              Warning
            </Typography>
            <p>
              {school?.name} is missing some quarter start dates or end dates. The appointment generator cannot work
              without them.
            </p>
          </Grid>
        )}
        <Grid item xs={12}>
          <FormGroup row>
            <FormControlLabel
              control={
                <Radio
                  name="academicYearSelection"
                  value="next"
                  color="primary"
                  onChange={null} // Can't be changed
                />
              }
              label="Next Academic Year"
              checked={yearlongGeneratorFormValues?.year === 'next'}
            />
          </FormGroup>
        </Grid>
        <Grid item xs={6}>
          <SelectInput
            value={yearlongGeneratorFormValues?.type_of_schedule}
            styleOverrides={classes.textInput}
            type="text"
            label="Type of Schedule"
            valueChanged={(value: string) => setTargetType(value)}
            choices={[
              { label: 'Minutes per Quarter', value: 'minutes_per_quarter' },
              { label: 'Appointments per Week', value: 'appointments_per_week' },
              // { label: 'Appointments per Year', value: 'appointments_per_year' },
            ]}
          />
        </Grid>
        <Grid item xs={6}>
          <SelectInput
            value={yearlongGeneratorFormValues?.frequency_count}
            styleOverrides={classes.textInput}
            type="text"
            label={countLabel}
            valueChanged={(value: string) =>
              setYearlongGeneratorFormValues({ ...yearlongGeneratorFormValues, frequency_count: value })
            }
            choices={countChoices}
            defaultChoice={{ value: '0', label: '(Choose)' }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextInput
            value={yearlongGeneratorFormValues?.schedule_date}
            styleOverrides={classes.textInput}
            type="date"
            shrinkLabel
            label={`Start Date ${startDateLabel}`}
            valueChanged={(value: string) => {
              setYearlongGeneratorFormValues({ ...yearlongGeneratorFormValues, schedule_date: value });
              setStartDateLabel(`(${stringToDayOfWeek(`${value} 12:00`)})`);
            }}
          />
          <p style={{ marginTop: '-10px' }}>This is the Q1 start date for {school?.name}</p>
        </Grid>
        <Grid item xs={6}>
          <SelectInput
            value={yearlongGeneratorFormValues?.location_id}
            styleOverrides={classes.textInput}
            type="text"
            label="Location"
            valueChanged={(value: string) =>
              setYearlongGeneratorFormValues({ ...yearlongGeneratorFormValues, location_id: value })
            }
            choices={appointmentChoices?.locations}
            defaultChoice={{ value: '', label: 'None' }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="h3" component="h3">
            Owner
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <FormGroup row>
            <Grid container>
              <Grid component={Box} key={ownerToDisplay?.label} item xs={12}>
                <FormControlLabel
                  control={
                    <Radio
                      name="ownerSelection"
                      value={ownerId}
                      color="primary"
                      onChange={null} // Can't be changed
                    />
                  }
                  label={ownerToDisplay?.label}
                  checked={true}
                />
              </Grid>
            </Grid>
          </FormGroup>
        </Grid>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => setFormShowing(!formShowing)}
          disabled={disablePreviewButton}
        >
          Preview Calendar
        </Button>
      </Grid>
    </>
  );
};

export default YAGFormUserVersion;
