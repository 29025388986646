import React, { useContext, useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import ConfirmCancelModal from '../../shared/components/modal/ConfirmCancelModal';
import { UserTicketsContext } from '../contexts/UserTicketsContext';
import TextInput from '../../shared/components/form/TextInput';
import InformationField from '../../shared/components/InformationField';
import SelectInput from '../../shared/components/form/SelectInput';
import { allAppointmentStatusChoices, ticketChoice } from '../../appointments/constants';
import { useStyles } from '../../shared/style/siteWideStyles';

const RejectTicketModal = () => {
  const classes = useStyles();

  const { updateUserTicket, ticketToEdit, rejectTicketModalOpen, setRejectTicketModalOpen } = useContext(
    UserTicketsContext,
  );
  const [formValues, setFormValues] = useState<any>();

  useEffect(() => {
    setFormValues({
      status: 'rejected',
      appointment_status: 'ticket',
    });
  }, [ticketToEdit]);

  const handleFormSubmit = () => {
    updateUserTicket(ticketToEdit, formValues);
  };

  const getStatusOptions = () => {
    return [...allAppointmentStatusChoices, ticketChoice];
  };

  return (
    <ConfirmCancelModal
      isOpen={rejectTicketModalOpen}
      title="Reject Appointment Ticket"
      openStatusChanged={() => setRejectTicketModalOpen(false)}
      onConfirm={handleFormSubmit}
      confirmLabel="Submit"
      height="600px"
    >
      <Grid container>
        <Grid item xs={3}>
          <InformationField value={ticketToEdit?.id} label="Ticket Number" />
        </Grid>
        <Grid item xs={3}>
          <InformationField value={ticketToEdit?.requested_by} label="Requested By" />
        </Grid>
        <Grid item xs={3}>
          <InformationField value={ticketToEdit?.created_at} label="Requested At" />
        </Grid>
        <Grid item xs={6}>
          <InformationField value={ticketToEdit?.description} label="Comment" />
        </Grid>
        <br />
        <Grid container justify="center" style={{ marginTop: '10px' }}>
          <p>
            <span style={{ fontWeight: 'bold' }}>Original Status:</span> &ensp;
            {ticketToEdit?.appointment_original_status}
          </p>
          <Grid item xs={10}>
            <SelectInput
              value={formValues?.appointment_status}
              styleOverrides={classes.selectInput}
              label="New Status"
              valueChanged={(value) => {
                setFormValues({ ...formValues, appointment_status: value });
              }}
              choices={getStatusOptions()}
              errorMessage={formValues?.appointment_status === 'ticket' ? 'must be selected for this appointment' : ''}
            />
          </Grid>
        </Grid>

        <Grid item xs={12} style={{ marginTop: '20px' }}>
          <TextInput
            multiline
            rows={8}
            value={formValues?.comment}
            label="Rejection Reason"
            valueChanged={(value: string) => setFormValues({ ...formValues, rejection_message: value })}
          />
        </Grid>
      </Grid>
    </ConfirmCancelModal>
  );
};

export default RejectTicketModal;
