/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext, FunctionComponent } from 'react';
import { Grid, Typography, FormGroup, FormControlLabel, Box, Radio, Button } from '@material-ui/core';
import { Service } from '../../services/model';
import { School } from '../../schools/model';
import SelectInput from '../../shared/components/form/SelectInput';
import TextInput from '../../shared/components/form/TextInput';
import { SelectOption } from '../../shared/common.model';
import { stringToDayOfWeek } from '../../shared/utils/date.utils';
import { UserContext } from '../../auth/contexts/userContext';
import { useStyles } from '../../shared/style/siteWideStyles';
import { AppointmentsContext } from '../contexts/AppointmentsContext';
import { today } from '../../shared/utils/date.utils';
import { AppointmentChoices } from '../model';

//* TODO: Rename this to YAGFormServiceVersion */

type YearlongAppointmentGeneratorFormProps = {
  service?: Service;
  school?: School;
  appointmentChoices?: AppointmentChoices;
};

const YearlongAppointmentGeneratorForm: FunctionComponent<YearlongAppointmentGeneratorFormProps> = (props) => {
  const { service, school, appointmentChoices } = props;
  const classes = useStyles();

  const [startDateLabel, setStartDateLabel] = useState('');
  const [disablePreviewButton, setDisablePreviewButton] = useState(true);
  const [currentQuarter, setCurrentQuarter] = useState(0);
  const [currentQuarterStartDate, setCurrentQuarterStartDate] = useState('');

  const { currentUserHasRole } = useContext(UserContext);
  const { yearlongGeneratorFormValues, setYearlongGeneratorFormValues, formShowing, setFormShowing } = useContext(
    AppointmentsContext,
  );

  const assignCurrentQuarter = (inputDate = new Date(today())) => {
    const todayDate = new Date(inputDate);
    const q1EndDate = new Date(school?.q1_ed);
    const q2EndDate = new Date(school?.q2_ed);
    const q3EndDate = new Date(school?.q3_ed);
    const q4EndDate = new Date(school?.q4_ed);

    if (todayDate < q1EndDate) {
      return 1;
    } else if (todayDate < q2EndDate) {
      return 2;
    } else if (todayDate < q3EndDate) {
      return 3;
    } else if (todayDate < q4EndDate) {
      return 4;
    } else {
      return 5;
    }
  };

  const schoolHasAllDates = () => {
    const attributesToCheck = ['q1_sd', 'q2_sd', 'q3_sd', 'q4_sd', 'q1_ed', 'q2_ed', 'q3_ed', 'q4_ed'];
    return attributesToCheck.every((attr) => school?.[attr]);
  };

  useEffect(() => {
    const newFormValues = { ...yearlongGeneratorFormValues, year: 'next' };

    if (service?.id) {
      newFormValues.referral_service_id = service.id;
    }

    if (school?.id) {
      const quarterToAssign = assignCurrentQuarter();
      const scheduleDateToAssign = school?.[`q${quarterToAssign}_sd`];
      setCurrentQuarter(quarterToAssign);
      setCurrentQuarterStartDate(scheduleDateToAssign);
      newFormValues.schedule_date = scheduleDateToAssign;
    }

    setYearlongGeneratorFormValues(newFormValues);
  }, [school, service]);

  useEffect(() => {
    if (
      yearlongGeneratorFormValues?.target_minutes &&
      yearlongGeneratorFormValues?.schedule_date &&
      yearlongGeneratorFormValues?.start_time &&
      yearlongGeneratorFormValues?.location_id &&
      // yearlongGeneratorFormValues?.owner &&
      schoolHasAllDates()
    ) {
      setDisablePreviewButton(false);
    }
  }, [yearlongGeneratorFormValues]);

  const quarterStartMessage = () => {
    if (yearlongGeneratorFormValues?.schedule_date === currentQuarterStartDate) {
      return `This is the Q${currentQuarter} start date for ${school?.name}`;
    } else if (currentQuarter === 5) {
      return `This school does not have quarter dates set up for the academic year`;
    } else {
      return `${school?.name} Q${currentQuarter} start date: ${currentQuarterStartDate}`;
    }
  };

  return (
    <>
      <Grid container>
        {!schoolHasAllDates() && (
          <Grid item xs={12}>
            <Typography variant="h3" component="h3">
              Warning
            </Typography>
            <p>
              {school?.name} is missing some quarter start dates or end dates. The appointment generator cannot work
              without them.
            </p>
          </Grid>
        )}
        <Grid item xs={12}>
          <FormGroup row>
            <FormControlLabel
              control={
                <Radio
                  name="academicYearSelection"
                  value="current"
                  color="primary"
                  onChange={(event) =>
                    setYearlongGeneratorFormValues({ ...yearlongGeneratorFormValues, year: 'current' })
                  }
                />
              }
              label="Current Academic Year"
              checked={yearlongGeneratorFormValues?.year === 'current'}
            />
            <FormControlLabel
              control={
                <Radio
                  name="academicYearSelection"
                  value="next"
                  color="primary"
                  onChange={(event) => setYearlongGeneratorFormValues({ ...yearlongGeneratorFormValues, year: 'next' })}
                />
              }
              label="Next Academic Year"
              checked={yearlongGeneratorFormValues?.year === 'next'}
            />
          </FormGroup>
        </Grid>
        <Grid item xs={6}>
          <SelectInput
            value={yearlongGeneratorFormValues?.target_minutes}
            styleOverrides={classes.textInput}
            type="text"
            label="Minutes Per Quarter"
            valueChanged={(value: string) =>
              setYearlongGeneratorFormValues({ ...yearlongGeneratorFormValues, target_minutes: value })
            }
            choices={[
              { label: '135', value: '135' },
              { label: '255', value: '255' },
              { label: '465', value: '465' },
              { label: '675', value: '675' },
              { label: '885', value: '885' },
            ]}
            defaultChoice={{ value: '', label: 'None' }}
          />
        </Grid>
        <Grid item xs={6}>
          {/* TODO Restore the actual value here */}
          <TextInput
            value={'2024-08-28'}
            styleOverrides={classes.textInput}
            type="date"
            shrinkLabel
            label={`Start Date ${startDateLabel}`}
            valueChanged={(value: string) => {
              setYearlongGeneratorFormValues({ ...yearlongGeneratorFormValues, schedule_date: value });
              setStartDateLabel(`(${stringToDayOfWeek(`${value} 12:00`)})`);
            }}
          />
          <p style={{ marginTop: '-10px' }}>{quarterStartMessage()}</p>
        </Grid>
        <Grid item xs={6}>
          <TextInput
            value={yearlongGeneratorFormValues?.start_time}
            styleOverrides={classes.textInput}
            type="time"
            shrinkLabel
            label="Start Time"
            valueChanged={(value: string) =>
              setYearlongGeneratorFormValues({ ...yearlongGeneratorFormValues, start_time: value })
            }
            inputProps={{ step: 300 }}
          />
        </Grid>
        {currentUserHasRole('admin') && (
          <Grid item xs={6}>
            <SelectInput
              value={yearlongGeneratorFormValues?.admin_units_override}
              styleOverrides={classes.textInput}
              type="text"
              label="Allow Service Provider to Exceed Maximum Units"
              valueChanged={(value: string) =>
                setYearlongGeneratorFormValues({ ...yearlongGeneratorFormValues, admin_units_override: value })
              }
              choices={[
                { value: 'true', label: 'Yes' },
                { value: 'false', label: 'No' },
              ]}
            />
          </Grid>
        )}
        <Grid item xs={6}>
          <SelectInput
            value={yearlongGeneratorFormValues?.location_id}
            styleOverrides={classes.textInput}
            type="text"
            label="Location"
            valueChanged={(value: string) =>
              setYearlongGeneratorFormValues({ ...yearlongGeneratorFormValues, location_id: value })
            }
            choices={appointmentChoices?.locations}
            defaultChoice={{ value: '', label: 'None' }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="h3" component="h3">
            Owners
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <FormGroup row>
            <Grid container>
              {appointmentChoices?.owners?.map((owner: SelectOption) => (
                <Grid component={Box} key={owner.label} item xs={12}>
                  <FormControlLabel
                    control={
                      <Radio
                        name="ownerSelection"
                        value={owner.value.toString()}
                        color="primary"
                        onChange={(event) =>
                          setYearlongGeneratorFormValues({ ...yearlongGeneratorFormValues, owner: event.target.value })
                        }
                      />
                    }
                    label={owner.label}
                    checked={yearlongGeneratorFormValues?.owner === owner.value.toString()}
                  />
                </Grid>
              ))}
            </Grid>
          </FormGroup>
        </Grid>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => setFormShowing(!formShowing)}
          disabled={disablePreviewButton}
        >
          Preview Calendar
        </Button>
      </Grid>
    </>
  );
};

export default YearlongAppointmentGeneratorForm;
