import React from 'react';
import { useHistory } from 'react-router-dom';
import { Typography, Grid, Card } from '@material-ui/core';

type Props = {};

const cardButtonStyles = {
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100%',
  width: '85%',
  padding: '0.5rem',
  paddingTop: '1rem',
};

const gridStyles = {
  marginBottom: '20px',
};

function AdminAccessPanel(props: Props) {
  const history = useHistory();

  return (
    <Grid container>
      <Grid item xs={12} style={{ marginBottom: '20px' }}>
        <Typography variant="h1">Admin Access Panel</Typography>
      </Grid>
      <Grid item xs={4} style={gridStyles}>
        <Card onClick={() => history.push('/email_alerts')} style={cardButtonStyles}>
          <Typography variant="h2">Email Alerts</Typography>
        </Card>
      </Grid>
      <Grid item xs={4} style={gridStyles}>
        <Card onClick={() => history.push('/grade_levels')} style={cardButtonStyles}>
          <Typography variant="h2">Grade Levels</Typography>
        </Card>
      </Grid>
      <Grid item xs={4} style={gridStyles}>
        <Card onClick={() => history.push('/referral_stages')} style={cardButtonStyles}>
          <Typography variant="h2">Referral Stages</Typography>
        </Card>
      </Grid>
      <Grid item xs={4} style={gridStyles}>
        <Card onClick={() => history.push('/renewal_stages')} style={cardButtonStyles}>
          <Typography variant="h2">Renewal Stages</Typography>
        </Card>
      </Grid>
      <Grid item xs={4} style={gridStyles}>
        <Card onClick={() => history.push('/renewal_types')} style={cardButtonStyles}>
          <Typography variant="h2">Renewal Types</Typography>
        </Card>
      </Grid>
      <Grid item xs={4} style={gridStyles}>
        <Card onClick={() => history.push('/listing_services')} style={cardButtonStyles}>
          <Typography variant="h2">Listing Services</Typography>
        </Card>
      </Grid>
      <Grid item xs={4} style={gridStyles}>
        <Card onClick={() => history.push('/application_settings')} style={cardButtonStyles}>
          <Typography variant="h2">Application Settings</Typography>
        </Card>
      </Grid>
    </Grid>
  );
}

export default AdminAccessPanel;
