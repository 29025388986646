import React, { FunctionComponent } from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import TextInput from '../../shared/components/form/TextInput';
import ErrorAlert from '../../shared/components/ErrorAlert';
import { _translateEach } from '../../shared/utils/translation.utils';
import { studentFormStore, studentBaseStore } from '../../shared/singletons';
import { useStoreObservable } from '../../shared/state/useStoreObservable.hook';

const useStyles = makeStyles({
  textInput: {
    height: '60px',
    width: '80%',
    margin: '10px',
  },
});

const StudentAddressForm: FunctionComponent = () => {
  const classes = useStyles();

  const { formErrorAlertVisible: showErrorAlert, formErrors, formValues } = useStoreObservable(studentFormStore);

  const t = _translateEach({
    title: 'students.form.addressTitle',
    address1: 'students.form.address1',
    address2: 'students.form.address2',
    state: 'students.form.state',
    city: 'students.form.city',
    zip: 'students.form.zip',
  });

  const handleFormValueChange = (key: string, value: string) => {
    studentFormStore.setState({ formValues: { ...formValues, [key]: value } });
  };

  React.useEffect(() => {
    studentBaseStore.fetchChoices();
  }, []);

  return (
    <form noValidate autoComplete="off">
      {formErrors?.base && (
        <ErrorAlert
          display={showErrorAlert}
          onClose={() => studentFormStore.setState({ formErrorAlertVisible: false })}
        >
          {formErrors?.base}
        </ErrorAlert>
      )}
      <Typography variant="h2">{t.title}</Typography>
      <TextInput
        styleOverrides={classes.textInput}
        type="text"
        label={t.address1}
        errorMessage={formErrors?.address_line1}
        value={formValues.address_line1}
        valueChanged={(value) => handleFormValueChange('address_line1', value)}
      />
      <TextInput
        styleOverrides={classes.textInput}
        type="text"
        label={t.address2}
        errorMessage={formErrors?.address_line2}
        value={formValues.address_line2}
        valueChanged={(value) => handleFormValueChange('address_line2', value)}
      />
      <TextInput
        styleOverrides={classes.textInput}
        type="text"
        label={t.city}
        errorMessage={formErrors?.address_city}
        value={formValues.address_city}
        valueChanged={(value) => handleFormValueChange('address_city', value)}
      />
      <TextInput
        styleOverrides={classes.textInput}
        type="text"
        label={t.state}
        errorMessage={formErrors?.address_state}
        value={formValues.address_state}
        valueChanged={(value) => handleFormValueChange('address_state', value)}
      />
      <TextInput
        styleOverrides={classes.textInput}
        type="text"
        label={t.zip}
        errorMessage={formErrors?.address_zip}
        value={formValues.address_zip}
        valueChanged={(value) => handleFormValueChange('address_zip', value)}
      />
    </form>
  );
};

export default StudentAddressForm;
