import React from 'react';
import EventBusyIcon from '@material-ui/icons/EventBusy';
import PermContactCalendarIcon from '@material-ui/icons/PermContactCalendar';
import AppointmentQuickStatusChange from './components/AppointmentQuickStatusChange';
import { Appointment } from './model';
import { SelectOption } from '../shared/common.model';
import { FilterProps } from '../shared/components/FilterBar';
import { Colors } from '../shared/style/colors';
import AppointmentRowMenu from './components/AppointmentRowMenu';

// Even though 'scheduled' doesn't exist in the database, a front-end appointment can have that status from the rabl node
export const statusColors = {
  complete: Colors.Success,
  incomplete: Colors.Red,
  scheduled: Colors.PurplePrimary,
  needs_rescheduled: Colors.PurpleThree,
  late_cancel: Colors.Orange,
  early_cancel: Colors.BabyBlue,
  no_show: Colors.GreyText,
};

export const allAppointmentStatusChoices = [
  { label: 'Incomplete', value: 'incomplete', disabled: true },
  { label: 'Scheduled', value: 'scheduled', disabled: true },
  { label: 'Needs Rescheduled', value: 'needs_rescheduled', disabled: true },
  { label: 'Early Cancel', value: 'early_cancel', disabled: false },
  { label: 'Late Cancel', value: 'late_cancel', disabled: false },
  { label: 'No Show', value: 'no_show', disabled: false },
  { label: 'Complete', value: 'complete', disabled: false },
];

// Don't forget to consider the date here as well
export const getAppointmentStatusOptions = (appointment?: Appointment, isAdmin = false) => {
  let choicesToReturn;

  if (isAdmin) {
    choicesToReturn = [...allAppointmentStatusChoices];
  } else {
    const afterStartDate = new Date(appointment?.start_time) > new Date();

    const baseStatuses = appointment?.possible_statuses || allAppointmentStatusChoices;

    // Remove scheduled and incomplete from the array of possible statuses
    const filteredStatuses = baseStatuses.filter((choice) => !['scheduled', 'incomplete'].includes(choice.value));
    choicesToReturn = [...filteredStatuses];

    // Return 'scheduled' and 'incomplete' in the array if the date is correct, or if the appointment's value is already set to one of them
    if (afterStartDate || appointment?.formatted_status === 'incomplete') {
      choicesToReturn.unshift({ label: 'Incomplete', value: 'incomplete', disabled: false });
    }
    if (!afterStartDate || appointment?.formatted_status === 'scheduled') {
      choicesToReturn.unshift({ label: 'Scheduled', value: 'scheduled', disabled: false });
    }
  }

  if (appointment?.status === 'ticket' || appointment?.formatted_status === 'ticket') {
    choicesToReturn.push(ticketChoice);
  }

  return choicesToReturn;
};

export const ticketChoice = {
  label: 'Ticket',
  value: 'ticket',
  disabled: true,
};

const StatusChangeCell = ({ appointment }: { appointment: Appointment }) => (
  <div className="appointments-list-quick-status-form">
    <AppointmentQuickStatusChange appointment={appointment} />
  </div>
);

const StatusShowCell = ({ appointment }: { appointment: Appointment }) => (
  <div
    style={{
      fontWeight: 700,
      textTransform: 'uppercase',
      color: statusColors?.[appointment.formatted_status],
    }}
  >
    {appointment?.formatted_status?.replace('_', ' ')}
  </div>
);

export const baseColumns = [
  {
    name: 'STUDENT NAME',
    selector: 'student_name',
    sortable: true,
    cell: (appointment: Appointment) => (
      <div>
        <a style={{ whiteSpace: 'break-spaces' }} href={`/students/${appointment.student_id}/profile`}>
          {`${appointment.student_name}\n(${appointment.student_slug})`}
        </a>
      </div>
    ),
  },
  {
    name: 'SCHOOL',
    selector: 'school_abbreviation',
    sortable: true,
    grow: 0.75,
  },
  {
    name: 'LOCATION',
    selector: 'location',
    sortable: true,
  },
  {
    name: 'SERVICE TYPE',
    selector: 'service_type',
    sortable: true,
    cell: (row) => (
      <div>
        <a href={`/students/${row.student_id}/services/${row.service_id}`}>{row.service_type}</a>
      </div>
    ),
  },
  {
    name: 'SCHEDULED DATE',
    selector: 'formatted_schedule_date',
    sortable: true,
    grow: 1.2,
    cell: (appointment: Appointment) => (
      <div>
        {['black', 'unknown'].includes(appointment?.blackout) && (
          <EventBusyIcon
            style={{ marginRight: '4px', top: '3px', position: 'relative', fontSize: '1.2em' }}
            fontSize="small"
          />
        )}
        {['inservice'].includes(appointment?.blackout) && (
          <PermContactCalendarIcon
            style={{ marginRight: '4px', top: '3px', position: 'relative', fontSize: '1.2em' }}
            fontSize="small"
          />
        )}
        {appointment?.formatted_schedule_date}
      </div>
    ),
  },
  {
    name: 'START TIME',
    selector: 'formatted_start_time',
    sortable: true,
    grow: 0.65,
  },
  {
    name: 'END TIME',
    selector: 'formatted_end_time',
    sortable: true,
    grow: 0.65,
  },
  {
    name: 'ORIGINAL DATE',
    selector: 'formatted_original_date',
    sortable: true,
    grow: 0.85,
  },
  {
    name: 'UNITS',
    selector: 'units',
    sortable: true,
    grow: 0.5,
  },
];

const conditionalStatusChangeColumn = {
  name: 'STATUS',
  selector: 'status',
  grow: 1.5,
  sortable: true,
  cell: (appointment: Appointment) => (
    <>
      {appointment?.owns_record_personally ? (
        <StatusChangeCell appointment={appointment} />
      ) : (
        <StatusShowCell appointment={appointment} />
      )}
    </>
  ),
};

export const readOnlyStatusColumn = {
  name: 'STATUS',
  selector: 'status',
  grow: 1.5,
  sortable: true,
  cell: (appointment: Appointment) => <StatusShowCell appointment={appointment} />,
};

const alwaysOnStatusChangeColumn = {
  name: 'STATUS',
  selector: 'status',
  grow: 1.5,
  sortable: true,
  cell: (appointment: Appointment) => <StatusChangeCell appointment={appointment} />,
};

const ownerColumn = {
  name: 'OWNER',
  selector: 'owner_last_name_first_name',
  sortable: true,
};

const lastModifiedColumn = {
  name: 'LAST MODIFIED',
  selector: 'last_modified',
  sortable: true,
  grow: 0.85,
};

const adminActionsColumn = {
  cell: (appointment: Appointment) => <AppointmentRowMenu appointment={appointment} />,
  name: 'ADMIN ACTIONS',
  allowOverflow: true,
  button: true,
  selector: 'actions',
};

export const serviceProviderAppointmentColumns = [...baseColumns, conditionalStatusChangeColumn];
export const supervisorAppointmentColumns = [...baseColumns, ownerColumn, conditionalStatusChangeColumn];
export const adminAppointmentColumns = [
  ...baseColumns,
  ownerColumn,
  lastModifiedColumn,
  alwaysOnStatusChangeColumn,
  adminActionsColumn,
];
export const accountantAppointmentColumns = [...baseColumns, conditionalStatusChangeColumn];

export const defaultFilters = (
  schoolChoices: SelectOption[],
  statusChoices: SelectOption[],
  ownerChoices: SelectOption[],
  supervisorChoices: SelectOption[],
  serviceTypeChoices: SelectOption[],
  showMultipleStatusSelect?: boolean,
): FilterProps[] => [
  {
    selector: 'ransack[original_date_gteq]',
    label: 'Original Date From',
    type: 'date',
    advanced: true,
  },
  {
    selector: 'ransack[original_date_lteq]',
    label: 'Original Date To',
    type: 'date',
    advanced: true,
  },
  {
    selector: 'ransack[patient_slug_cont]',
    label: 'Timeline ID',
    type: 'text',
  },
  {
    selector: 'ransack[patient_last_name_cont]',
    label: 'Student Last Name',
    type: 'text',
  },
  {
    selector: 'ransack[payer_id_eq]',
    label: 'School',
    type: 'text',
    options: schoolChoices,
    advanced: true,
  },
  {
    selector: showMultipleStatusSelect ? 'ransack[status_matches_any]' : 'ransack[status_eq]',
    label: 'Status',
    type: 'text',
    options: statusChoices,
    multiple: showMultipleStatusSelect ? true : false,
  },
  {
    selector: 'ransack[start_time_gteq]',
    label: 'Date From',
    type: 'date',
  },
  {
    selector: 'ransack[start_time_lteq]',
    label: 'Date To',
    type: 'date',
  },
  {
    selector: 'ransack[service_id_eq]',
    label: 'Service Type',
    type: 'text',
    options: serviceTypeChoices,
  },
  {
    selector: 'ransack[user_id_eq]',
    label: 'Owner',
    type: 'text',
    options: ownerChoices,
    advanced: true,
  },
  {
    selector: 'ransack[user_supervisor_id_eq]',
    label: 'Supervisor',
    type: 'text',
    options: supervisorChoices,
    advanced: true,
  },
  {
    selector: 'ransack[blackout_eq]',
    label: 'School Closing',
    type: 'text',
    advanced: true,
    options: [
      { label: 'Open', value: 'open' },
      { label: 'Closure', value: 'black' },
      { label: 'Inservice', value: 'inservice' },
    ],
  },
];
