/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { CircularProgress, Box } from '@mui/material';
import { axios } from '../../shared/singletons';
import RescheduleAllFutureAppointmentsForm from '../components/RescheduleAllFutureAppointmentsForm';
import RescheduleAppointmentPreview from '../components/RescheduleAppointmentPreview';
import { appointmentRecordStore } from '../../shared/singletons';
import { useStoreObservable } from '../../shared/state/useStoreObservable.hook';
import { AppointmentRecordState } from '../appointmentRecord.store';
import { AppointmentRouteParams } from '../model';
import { UserContext } from '../../auth/contexts/userContext';

const AppointmentSchedulerPage: React.FC = () => {
  const { studentId, appointmentId } = useParams<AppointmentRouteParams>();
  const { currentUserHasRole } = React.useContext(UserContext);
  const { appointmentLoading } = useStoreObservable<AppointmentRecordState>(appointmentRecordStore);

  const [appointmentFetched, setAppointmentFetched] = React.useState(false);
  const [dayOfWeekOptions, setDayOfWeekOptions] = React.useState([]);
  const [siblingCounter, setSiblingCounter] = React.useState(1);
  const [showPreview, setShowPreview] = React.useState(false);

  const query = new URLSearchParams(useLocation().search);
  const filterParams = query.get('filters')?.split('__')?.join('&');

  React.useEffect(() => {
    axios
      .get(`/bulk_appointments/${appointmentId}/edit`)
      .then((response) => {
        setDayOfWeekOptions(response.data.day_of_week_options);
        setSiblingCounter(response.data.sibling_counter);
      })
      .catch((error) => {
        console.error('Error fetching day of week options:', error);
      });

    if (!appointmentFetched) {
      setAppointmentFetched(true);
      appointmentRecordStore.fetchRecord(studentId, appointmentId);
    }
  }, []);

  React.useEffect(() => {
    if (!appointmentFetched) {
      setAppointmentFetched(true);
      appointmentRecordStore.fetchRecord(studentId, appointmentId);
    }
  }, [currentUserHasRole, filterParams, appointmentFetched, appointmentId, studentId]);

  return appointmentLoading ? (
    <Box sx={{ display: 'inline-block', width: '100%', textAlign: 'center', marginTop: 25 }}>
      <p style={{ fontSize: '18px' }}>Loading appointment...</p>
      <br />
      <CircularProgress />
    </Box>
  ) : (
    <>
      {showPreview ? (
        <RescheduleAppointmentPreview setShowPreview={setShowPreview} />
      ) : (
        <RescheduleAllFutureAppointmentsForm
          onSuccess={() => setShowPreview(true)}
          dayOfWeekOptions={dayOfWeekOptions}
          siblingCounter={siblingCounter}
        />
      )}
    </>
  );
};

export default AppointmentSchedulerPage;
