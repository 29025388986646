import React, { FunctionComponent, ChangeEvent } from 'react';
import { Typography, makeStyles } from '@material-ui/core';
import TextInput from '../../shared/components/form/TextInput';
import SelectInput from '../../shared/components/form/SelectInput';
import ErrorAlert from '../../shared/components/ErrorAlert';
import { _translateEach } from '../../shared/utils/translation.utils';
import { studentBaseStore, studentFormStore } from '../../shared/singletons';
import { useStoreObservable } from '../../shared/state/useStoreObservable.hook';

const useStyles = makeStyles({
  textInput: {
    height: '60px',
    width: '80%',
    margin: '10px',
  },
  formTitle: {
    marginLeft: '12px',
  },
});

const StudentDetailsForm: FunctionComponent = () => {
  const classes = useStyles();
  const { formErrorAlertVisible: showErrorAlert, formErrors, formValues } = useStoreObservable(studentFormStore);
  const { gradeLevelChoices, locationChoices, schoolChoices, statusChoices } = useStoreObservable(studentBaseStore);

  const t = _translateEach({
    firstName: 'students.form.firstName',
    lastName: 'students.form.lastName',
    school: 'students.form.school',
    teacherName: 'students.form.teacherName',
    roomNumber: 'students.form.roomNumber',
    dateOfBirth: 'students.form.dateOfBirth',
    preferredLocation: 'students.form.preferredLocation',
    gradeLevel: 'students.form.gradeLevel',
    defaultSelectOption: 'selectOption.defaultSelectOption',
  });

  const handleFormValueChange = (key: string, value: string) => {
    studentFormStore.setState({ formValues: { ...formValues, [key]: value } });
  };

  React.useEffect(() => {
    studentBaseStore.fetchChoices();
  }, []);

  return (
    <form noValidate autoComplete="off">
      <Typography className={classes.formTitle} variant="h2" component="h2">
        Student Details
      </Typography>
      {formErrors?.base && (
        <ErrorAlert
          display={showErrorAlert}
          onClose={() => studentFormStore.setState({ formErrorAlertVisible: false })}
        >
          {formErrors?.base}
        </ErrorAlert>
      )}
      <TextInput
        styleOverrides={classes.textInput}
        type="text"
        label={t.firstName}
        errorMessage={formErrors?.first_name}
        value={formValues?.first_name}
        valueChanged={(value) => handleFormValueChange('first_name', value.trim())}
      />
      <TextInput
        styleOverrides={classes.textInput}
        type="text"
        label={t.lastName}
        errorMessage={formErrors?.last_name}
        value={formValues?.last_name}
        valueChanged={(value) => handleFormValueChange('last_name', value.trim())}
      />
      <SelectInput
        styleOverrides={classes.textInput}
        type="text"
        label={t.school}
        errorMessage={formErrors?.payer_id}
        value={formValues?.payer_id}
        choices={
          formValues?.id ? schoolChoices.filter((choice) => choice.value === formValues?.payer_id) : schoolChoices
        }
        onChange={(e: ChangeEvent<HTMLInputElement>) => handleFormValueChange('payer_id', e.target.value)}
      />
      <SelectInput
        styleOverrides={classes.textInput}
        type="text"
        label="Status"
        errorMessage={formErrors?.discharged}
        value={formValues?.discharged}
        choices={statusChoices}
        onChange={(e: ChangeEvent<HTMLInputElement>) => handleFormValueChange('discharged', e.target.value)}
      />
      <TextInput
        styleOverrides={classes.textInput}
        type="text"
        label={t.teacherName}
        errorMessage={formErrors?.teacher}
        value={formValues?.teacher}
        valueChanged={(value) => handleFormValueChange('teacher', value)}
      />
      <TextInput
        styleOverrides={classes.textInput}
        type="text"
        label={t.roomNumber}
        errorMessage={formErrors?.room_number}
        value={formValues?.room_number}
        valueChanged={(value) => handleFormValueChange('room_number', value)}
      />
      <TextInput
        styleOverrides={classes.textInput}
        type="date"
        shrinkLabel
        label={t.dateOfBirth}
        errorMessage={formErrors?.birthdate}
        value={formValues?.birthdate}
        valueChanged={(value) => handleFormValueChange('birthdate', value)}
      />
      <SelectInput
        styleOverrides={classes.textInput}
        type="text"
        label={t.preferredLocation}
        errorMessage={formErrors?.location_id}
        value={formValues?.location_id}
        choices={locationChoices}
        onChange={(e: ChangeEvent<HTMLInputElement>) => handleFormValueChange('location_id', e.target.value)}
      />
      <SelectInput
        styleOverrides={classes.textInput}
        type="text"
        label={t.gradeLevel}
        errorMessage={formErrors?.grade_level_id}
        value={formValues?.grade_level_id}
        choices={gradeLevelChoices}
        onChange={(e: ChangeEvent<HTMLInputElement>) => handleFormValueChange('grade_level_id', e.target.value)}
      />
      <TextInput
        styleOverrides={classes.textInput}
        type="text"
        label="Email (Optional)"
        errorMessage={formErrors?.email_student}
        value={formValues?.email_student}
        valueChanged={(value) => handleFormValueChange('email_student', value)}
      />
    </form>
  );
};

export default StudentDetailsForm;
