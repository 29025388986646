import React, { useContext } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { UserContext } from './modules/auth/contexts/userContext';

import AppointmentsListPage from './modules/appointments/pages/AppointmentsListPage';
import AppointmentSchedulerPage from './modules/appointments/pages/AppointmentSchedulerPage';
import AppointmentDetailPage from './modules/appointments/pages/AppointmentDetailPage';
import RescheduleAppointmentPage from './modules/appointments/pages/RescheduleAppointmentPage';
import YearlongAppointmentGeneratorPage from './modules/appointments/pages/YearlongAppointmentGeneratorPage';

import ReportsPage from './modules/reports/pages/ReportsPage';
import DashboardPage from './modules/dashboard/pages/DashboardPage';
import NewSessionNotePage from './modules/sessionNotes/pages/NewSessionNotePage';
import NewSchoolClosingPage from './modules/schoolClosings/pages/NewSchoolClosingPage';
import DischargeSchoolPage from './modules/schools/pages/DischargeSchoolPage';
import RenewalsCaseloadPage from './modules/renewals/pages/RenewalsCaseloadPage';
import RenewalDetailPage from './modules/renewals/pages/RenewalDetailPage';
import SchoolsListPage from './modules/schools/pages/SchoolsListPage';
import ComplianceTrackingListPage from './modules/complianceTracking/pages/ComplianceTrackingListPage';
import SchoolActionsListPage from './modules/schoolActions/pages/SchoolActionsList';
import UserTicketsListPage from './modules/userTickets/pages/UserTicketsList';

import SchoolNewPage from './modules/schools/pages/SchoolNewPage';
import SchoolDetailPage from './modules/schools/pages/SchoolDetailPage';
import LocationDetailPage from './modules/schools/pages/LocationDetailPage';
import ReferralsListPage from './modules/referrals/pages/ReferralsListPage';
import NewReferralPage from './modules/referrals/pages/NewReferral';
import ReviewReferralStudentPage from './modules/referrals/pages/ReviewReferralStudent';
import NewReferralStudentPage from './modules/referrals/pages/NewReferralStudent';
// import ServicesListPage from './modules/services/pages/ServicesListPage';
import ServiceDetailPage from './modules/services/pages/ServiceDetailPage';

import StudentsListPage from './modules/students/pages/StudentsListPage';
import StudentDetailPage from './modules/students/pages/StudentDetailPage';
import DuplicateAppointmentsPage from './modules/duplicateAppointments/pages/DuplicateAppointmentsPage';
import SchoolEsySchedulingPage from './modules/duplicateAppointments/pages/SchoolEsySchedulingPage';

import UsersListPage from './modules/users/pages/UsersListPage';
import UserDetailPage from './modules/users/pages/UserDetailPage';
import NewUserPage from './modules/users/pages/NewUserPage';
import CaseLoadManagementPage from './modules/users/pages/CaseLoadManagementPage';
import AccountEditPage from './modules/account/pages/AccountEditPage';
import MyDocuments from './modules/schoolUsers/pages/MyDocuments';
import SchoolUserHelp from './modules/schoolUsers/pages/SchoolUserHelp';
import ApplicationSettingsPage from './modules/admin/applicationSettings/pages/ApplicationSettingsPage';
import LoginPage from './modules/login/pages/LoginPage';
import PasswordResetEmailRequestPage from './modules/passwords/pages/PasswordResetEmailRequestPage';
import PasswordResetUpdatePage from './modules/passwords/pages/PasswordResetUpdatePage';
import GradeLevelsListPage from './modules/admin/gradeLevels/pages/GradeLevelsPage';
import ListingServices from './modules/admin/listingServices/pages/ListingServicesPage';
import RenewalStages from './modules/admin/renewalStages/pages/RenewalStagesPage';
import RenewalTypes from './modules/admin/renewalTypes/pages/RenewalTypesPage';
import Roles from './modules/admin/userRoles/pages/UserRolesPage';
import ReferralStagesListPage from './modules/admin/referralStages/pages/ReferralStagesListPage';

import StudentDischargeCompletePage from './modules/students/pages/DischargeCompletePage';
import StudentDischargePage from './modules/students/pages/StudentDischargePage';
import StudentDeletePage from './modules/students/pages/StudentDeletePage';

import NewStudentPage from './modules/studentBuilder/pages/NewStudentPage';
import EditStudentInfoPage from './modules/studentBuilder/pages/EditStudentInfoPage';
import EditContactInfoPage from './modules/studentBuilder/pages/EditContactInfoPage';
import AddStudentServicesPage from './modules/studentBuilder/pages/AddStudentServicesPage';
import NewStudentGoalsRenewalsPage from './modules/studentBuilder/pages/NewStudentGoalsRenewalsPage';

import AnnualUpdaterPageAttachments from './modules/annualUpdater/pages/AnnualUpdaterAttachments';
import AnnualUpdaterPageServices from './modules/annualUpdater/pages/AnnualUpdaterServices';
import AnnualUpdaterPageGoals from './modules/annualUpdater/pages/AnnualUpdaterGoals';
import AnnualUpdaterPageRenewals from './modules/annualUpdater/pages/AnnualUpdaterRenewals';
import AnnualUpdaterPageFinish from './modules/annualUpdater/pages/AnnualUpdaterFinish';

import TrainingsListPage from './modules/trainings/pages/TrainingsListPage';
import TrainingDetailPage from './modules/trainings/pages/TrainingDetailPage';
import MyTrainingsPage from './modules/trainings/pages/MyTrainingsPage';

import NewEmailAlertPage from './modules/emailAlerts/pages/NewEmailAlert';
import EmailAlertsListPage from './modules/emailAlerts/pages/EmailAlertsList';
import EditEmailAlertPage from './modules/emailAlerts/pages/EditEmailAlertPage';

import AdminAccessPanel from './modules/admin/pages/AdminAccessPanel';

const App: React.FC = () => {
  const { user } = useContext(UserContext);

  const ProtectedRoute = ({ component: Component, path, exact = true }) => (
    <Route exact={exact} path={path} render={(props) => (user.id ? <Component props={props} component /> : <Redirect to="/login" />)} />
  );

  const AuthRoute = ({ component: Component, path }) => <Route path={path} render={(props) => (user.id === undefined ? <Component props={props} component /> : <Redirect to="/" />)} />;

  return user ? (
    <Switch>
      {/* Components for Annual Updater */}
      <ProtectedRoute exact path="/students/:studentId/annual_updater_attachments" component={() => <AnnualUpdaterPageAttachments />} />
      <ProtectedRoute exact path="/students/:studentId/annual_updater_services" component={() => <AnnualUpdaterPageServices />} />
      <ProtectedRoute exact path="/students/:studentId/annual_updater_goals" component={() => <AnnualUpdaterPageGoals />} />
      <ProtectedRoute exact path="/students/:studentId/annual_updater_renewals" component={() => <AnnualUpdaterPageRenewals />} />
      <ProtectedRoute exact path="/students/:studentId/annual_updater_finish" component={() => <AnnualUpdaterPageFinish />} />

      <ProtectedRoute exact path="/students/:studentId/appointments/:appointmentId/edit" component={() => <RescheduleAppointmentPage />} />
      <ProtectedRoute exact path="/students/:studentId/appointments/create" component={() => <AppointmentsListPage />} />
      <ProtectedRoute exact path="/students/:studentId/appointments/:appointmentId/bulk-reschedule" component={() => <AppointmentSchedulerPage />} />
      <ProtectedRoute exact path="/students/:studentId/appointments/:appointmentId/session_notes/new" component={() => <NewSessionNotePage />} />
      <ProtectedRoute exact path="/students/:studentId/appointments/:appointmentId/:tab" component={() => <AppointmentDetailPage />} />
      <ProtectedRoute exact path="/students/:studentId/appointments/:appointmentId" component={() => <AppointmentDetailPage />} />
      <ProtectedRoute exact path="/appointments" component={() => <AppointmentsListPage />} />
      <ProtectedRoute exact path="/students/:studentId/services/:serviceId/:tab" component={() => <ServiceDetailPage />} />
      <ProtectedRoute exact path="/students/:studentId/services/:serviceId" component={() => <ServiceDetailPage />} />
      <ProtectedRoute exact path="/referrals" component={() => <ReferralsListPage />} />
      <ProtectedRoute exact path="/referrals/:studentId/new" component={() => <NewReferralPage />} />
      <ProtectedRoute exact path="/referrals/:studentId/review_student" component={() => <ReviewReferralStudentPage />} />
      <ProtectedRoute exact path="/referral_students/new" component={() => <NewReferralStudentPage />} />
      {/* <ProtectedRoute exact path="/services" component={() => <ServicesListPage />} /> */}
      <ProtectedRoute exact path="/services/:serviceId/appointments/yearlong-generator" component={() => <YearlongAppointmentGeneratorPage />} />
      {/* <ProtectedRoute exact path="/students/:studentId/renewals/create" component={() => <RenewalsListPage />} /> */}
      <ProtectedRoute exact path="/students/:studentId/renewals/:renewalId/:tab" component={() => <RenewalDetailPage />} />
      <ProtectedRoute exact path="/students/:studentId/renewals/:renewalId" component={() => <RenewalDetailPage />} />
      <ProtectedRoute exact path="/renewals" component={() => <RenewalsCaseloadPage />} />
      <ProtectedRoute exact path="/renewals-caseload" component={() => <RenewalsCaseloadPage />} />
      <ProtectedRoute exact path="/schools/:schoolId/esy_scheduling" component={() => <SchoolEsySchedulingPage />} />
      <ProtectedRoute exact path="/schools/:schoolId/discharge" component={() => <DischargeSchoolPage />} />
      <ProtectedRoute exact path="/schools/:schoolId/school_closings" component={() => <NewSchoolClosingPage />} />
      <ProtectedRoute exact path="/schools/new" component={() => <SchoolNewPage />} />
      <ProtectedRoute exact path="/schools/:schoolId/:tab" component={() => <SchoolDetailPage />} />
      <ProtectedRoute exact path="/schools/:schoolId" component={() => <SchoolDetailPage />} />
      <ProtectedRoute exact path="/schools" component={() => <SchoolsListPage />} />
      <ProtectedRoute exact path="/school_actions" component={() => <SchoolActionsListPage />} />
      <ProtectedRoute exact path="/locations/:locationId" component={() => <LocationDetailPage />} />
      <ProtectedRoute exact path="/user_tickets" component={() => <UserTicketsListPage />} />

      <ProtectedRoute exact path="/compliance_tracking" component={() => <ComplianceTrackingListPage />} />
      <ProtectedRoute exact path="/users/new" component={() => <NewUserPage />} />
      <ProtectedRoute exact path="/users/:userId/duplicate_appointments/:studentId" component={() => <DuplicateAppointmentsPage />} />
      <ProtectedRoute exact path="/users/:userId/duplicate_appointments/" component={() => <DuplicateAppointmentsPage />} />
      <ProtectedRoute exact path="/users/:userId/transfer_case_load" component={() => <CaseLoadManagementPage />} />
      <ProtectedRoute exact path="/users/:userId/:tab" component={() => <UserDetailPage />} />
      <ProtectedRoute exact path="/users/:userId" component={() => <UserDetailPage />} />
      <ProtectedRoute exact path="/users" component={() => <UsersListPage />} />

      <ProtectedRoute exact path="/students/new" component={() => <NewStudentPage />} />
      <ProtectedRoute exact path="/students/:studentId/discharge/complete" component={() => <StudentDischargeCompletePage />} />
      <ProtectedRoute exact path="/students/:studentId/discharge" component={() => <StudentDischargePage />} />

      <ProtectedRoute exact path="/students/:studentId/delete" component={() => <StudentDeletePage />} />
      <ProtectedRoute exact path="/students/:studentId/add_services" component={() => <AddStudentServicesPage />} />
      <ProtectedRoute exact path="/students/:studentId/edit_contact_info/:contactOnly" component={() => <EditContactInfoPage />} />
      <ProtectedRoute exact path="/students/:studentId/edit_contact_info/" component={() => <EditContactInfoPage />} />
      <ProtectedRoute exact path="/students/:studentId/edit_details" component={() => <EditStudentInfoPage />} />
      <ProtectedRoute exact path="/students/:studentId/goals_and_renewals" component={() => <NewStudentGoalsRenewalsPage />} />
      <ProtectedRoute exact path="/students/:studentId/:tab" component={() => <StudentDetailPage />} />
      <ProtectedRoute exact path="/students/:studentId" component={() => <StudentDetailPage />} />
      <ProtectedRoute exact path="/students" component={() => <StudentsListPage />} />
      <ProtectedRoute exact path="/manage_trainings" component={() => <TrainingsListPage />} />
      <ProtectedRoute exact path="/manage_trainings/:trainingId" component={() => <TrainingDetailPage />} />
      <ProtectedRoute exact path="/my_trainings" component={() => <MyTrainingsPage />} />

      <ProtectedRoute exact path="/email_alerts/new" component={() => <NewEmailAlertPage />} />
      <ProtectedRoute exact path="/email_alerts" component={() => <EmailAlertsListPage />} />
      <ProtectedRoute exact path="/email_alerts/:emailAlertId" component={() => <EditEmailAlertPage />} />

      <ProtectedRoute exact path="/grade_levels" component={() => <GradeLevelsListPage />} />
      <ProtectedRoute exact path="/listing_services" component={() => <ListingServices />} />
      <ProtectedRoute exact path="/referral_stages" component={() => <ReferralStagesListPage />} />
      <ProtectedRoute exact path="/renewal_stages" component={() => <RenewalStages />} />
      <ProtectedRoute exact path="/renewal_types" component={() => <RenewalTypes />} />
      <ProtectedRoute exact path="/roles" component={() => <Roles />} />
      <ProtectedRoute exact path="/account" component={() => <AccountEditPage />} />
      <ProtectedRoute exact path="/help" component={() => <SchoolUserHelp />} />
      <ProtectedRoute exact path="/documents" component={() => <MyDocuments />} />
      <ProtectedRoute exact path="/application_settings" component={() => <ApplicationSettingsPage />} />
      <ProtectedRoute exact path="/reports" component={() => <ReportsPage />} />
      <ProtectedRoute exact path="/dashboard" component={() => <DashboardPage />} />
      <ProtectedRoute exact path="/admin_access_panel" component={() => <AdminAccessPanel />} />
      {/* <ProtectedRoute exact path="/service-provider-dashboard" component={() => <DashboardDetailPage />} /> */}

      <AuthRoute path="/login" component={() => <LoginPage />} />
      <AuthRoute path="/password-reset" component={() => <PasswordResetEmailRequestPage />} />
      <AuthRoute path="/password-update/:token" component={() => <PasswordResetUpdatePage />} />

      <ProtectedRoute exact path="/" component={() => <DashboardPage />} />
    </Switch>
  ) : (
    <div />
  );
};

export default App;
