import React, { FunctionComponent, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import StudentDetail from '../components/StudentDetail';
import { useStoreObservable } from '../../shared/state/useStoreObservable.hook';
import { studentRecordStore } from '../../shared/singletons';
import { StudentRouteParams } from '../model';
import { ServiceFormOptionsContext } from '../../services/contexts/ServiceFormOptionsContext';

export type StudentDetailPageProps = Record<string, unknown>;

const StudentDetailPage: FunctionComponent<StudentDetailPageProps> = () => {
  const { student, studentLoading } = useStoreObservable(studentRecordStore);
  const { studentId, tab } = useParams<StudentRouteParams>();
  const { fetchChoices } = React.useContext(ServiceFormOptionsContext);

  /* eslint-disable */
  useEffect(() => {
    fetchChoices();
    studentRecordStore.fetchRecord(studentId);
  }, [studentId]);
  /* eslint-enable */

  return <StudentDetail student={student} tab={tab} studentLoading={studentLoading} />;
};

export default StudentDetailPage;
