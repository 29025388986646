import React from 'react';
import { Grid } from '@material-ui/core';
import { appointmentRecordStore, appointmentBaseStore } from '../../shared/singletons';
import { useStoreObservable } from '../../shared/state/useStoreObservable.hook';
import FilterBar, { FilterProps } from '../../shared/components/FilterBar';
import { defaultFilters } from '../constants';
import { BaseRecordStore } from '../../shared/state/baseRecord.store';
import { AppointmentsContext } from '../contexts/AppointmentsContext';
import { allAppointmentStatusChoices } from '../constants';

interface AppointmentFilterBarProps {
  // Props for when AppointmentFilterBar is nested inside other page
  filters?: FilterProps[];
  filterStore?: BaseRecordStore<unknown>;
  onFilter?: () => void;
  resetPagination?: boolean;
  setResetPagination?: any;
  showMultipleStatusSelect?: boolean;
}

const AppointmentFilterBar: React.FunctionComponent<AppointmentFilterBarProps> = (props) => {
  const { schoolChoices, allOwnerChoices, supervisorChoices, serviceTypeChoices } = useStoreObservable(
    appointmentBaseStore,
  );
  const { fetchDownloadableAppointments } = React.useContext(AppointmentsContext);

  const {
    // Props for when AppointmentFilterBar is nested inside other page
    showMultipleStatusSelect,
    filters = defaultFilters(
      schoolChoices,
      allAppointmentStatusChoices,
      allOwnerChoices,
      supervisorChoices,
      serviceTypeChoices,
      showMultipleStatusSelect,
    ),
    filterStore = appointmentRecordStore,
    onFilter,
    resetPagination,
    setResetPagination,
  } = props;

  const handleFilter = (filterParams: string) => {
    if (setResetPagination) {
      setResetPagination(!resetPagination);
    }

    if (onFilter) {
      onFilter();
    } else {
      appointmentRecordStore.fetchRecords(filterParams, 1, 25);
      fetchDownloadableAppointments(filterParams);
    }
  };

  return (
    <Grid item xs={12}>
      <FilterBar
        filters={filters.filter((filter) => !filter.advanced)}
        advancedFilters={filters.filter((filter) => filter.advanced)}
        onFilter={handleFilter}
        baseStore={filterStore}
        onExport={null}
      />
    </Grid>
  );
};

export default AppointmentFilterBar;
