import React, { useState, createContext } from 'react';
import { useHistory } from 'react-router-dom';
import { axios } from '../../shared/singletons';
import { ReferralChoicesResponse, CreateReferralStudentResponse } from '../model';
import { SelectOption } from '../../shared/common.model';
import { Student, StudentFormValues } from '../../students/model';
import { ServiceResponse } from '../../services/model';

interface ReferralsContextInterface {
  fetchChoices?: () => Promise<void>;

  serviceTypeChoices?: SelectOption[];
  studentChoices?: SelectOption[];
  schoolChoices?: SelectOption[];
  locationChoices?: SelectOption[];

  fetchReferralStudent?: (studentId: string) => Promise<void>;
  referralStudent?: Student;

  createReferralStudent?: (formValues: StudentFormValues) => Promise<void>;
  updateReferralStudent?: (studentId: string, formValues: StudentFormValues) => Promise<void>;
  createReferral?: (
    studentId: string,
    formValues: Record<string, string>,
    attachments: FileList[],
    onSuccess: any,
  ) => Promise<void>;

  schoolUserCreateReferral?: (
    studentId: string,
    formValues: Record<string, string>,
    attachments: FileList[],
    onSuccess: any,
  ) => Promise<void>;
  referralSubmitting?: boolean;
  formErrors?: Record<string, string>;
  serviceId?: string;

  handleRequiredUpload?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSelectedFile?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  optionalAttachedFiles?: FileList[];

  requiredAttachment?: FileList;
}

const ReferralsContext = createContext<ReferralsContextInterface>({});

const ReferralsContextConsumer = ReferralsContext.Consumer;
const ReferralsContextProvider = ({ children }) => {
  const history = useHistory();

  const [serviceTypeChoices, setServiceTypeChoices] = useState<SelectOption[]>([]);
  const [studentChoices, setStudentChoices] = useState<SelectOption[]>([]);
  const [schoolChoices, setSchoolChoices] = useState<SelectOption[]>([]);
  const [locationChoices, setLocationChoices] = useState<SelectOption[]>([]);
  const [referralStudent, setReferralStudent] = useState<Student>({});
  const [formErrors, setFormErrors] = useState({});
  const [referralSubmitting, setReferralSubmitting] = useState<boolean>(false);
  const [serviceId, setServiceId] = useState<string>();
  const [optionalAttachedFiles, setOptionalAttachedFiles] = React.useState<FileList[]>([]);
  const [requiredAttachment, setRequiredAttachment] = React.useState<FileList>(undefined);

  const fetchReferralStudent = async (studentId: string) => {
    axios
      .get<string, any>(`patients/${studentId}.json`)
      .then((r) => {
        setReferralStudent(r.data?.result);
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          window.location.href = '/';
        }
      });
  };

  const createReferralStudent = async (formValues: StudentFormValues) => {
    axios
      .post<string, CreateReferralStudentResponse>(`patients.json`, {
        patient: formValues,
      })
      .then((r: CreateReferralStudentResponse) => {
        history.push(`/referrals/${r.data.result.id}/new`);
      })
      .catch((error) => {
        window.scrollTo(0, 0);
        setFormErrors(error.response?.data);
      });
  };

  const updateReferralStudent = async (studentId: string, formValues: StudentFormValues) => {
    axios
      .put<string, ReferralChoicesResponse>(`patients/${studentId}.json`, {
        patient: formValues,
      })
      .then(() => {
        history.push(`/referrals/${studentId}/new`);
      })
      .catch((error) => {
        window.scrollTo(0, 0);
        setFormErrors(error.response?.data);
      });
  };

  const createReferral = async (
    patient_id: string,
    formValues: Record<string, string>,
    attachments: FileList[],
    onSuccess: any,
  ) => {
    setReferralSubmitting(true);

    axios
      .post<string, ServiceResponse>(`/patients/${patient_id}/referral_services.json`, {
        referral_service: formValues,
      })
      .then((response: ServiceResponse) => {
        const responses = attachments.map((attachmentList) => {
          return Array.from(attachmentList).forEach((attachment) => {
            const data = new FormData();

            data.append(`file`, attachment, attachment.name);
            data.append('category', 'other');

            return axios.post(`/patients/${patient_id}/attachments.json`, data);
          });
        });

        Promise.all(responses).then(() => {
          setTimeout(() => {
            onSuccess();
            setReferralSubmitting(false);
          }, 1000);
        });
      });
  };

  const schoolUserCreateReferral = async (
    patient_id: string,
    formValues: Record<string, string>,
    attachments: FileList[],
    onSuccess: any,
  ) => {
    setReferralSubmitting(true);

    axios
      .post<string, ServiceResponse>(`/patients/${patient_id}/referral_services/school_user_create.json`, {
        referral_service: formValues,
      })
      .then((response: ServiceResponse) => {
        setServiceId(response.data.id);
        const responses = attachments.map((attachmentList, idx) => {
          return Array.from(attachmentList).forEach((attachment) => {
            const data = new FormData();

            data.append(`file`, attachment, attachment.name);
            data.append('category', 'other');

            return axios.post(`/patients/${patient_id}/attachments.json`, data);
          });
        });

        Promise.all(responses).then(() => {
          setTimeout(() => {
            onSuccess();
            setReferralSubmitting(false);
          }, 1000);
        });
      });
  };

  const fetchChoices = async () => {
    axios.get<string, ReferralChoicesResponse>(`referrals/new.json`).then((r: ReferralChoicesResponse) => {
      const { service_types, student_choices, school_choices, location_choices } = r.data?.result;

      setServiceTypeChoices(service_types);
      setStudentChoices(student_choices);
      setSchoolChoices(school_choices);
      setLocationChoices(location_choices);
    });
  };

  const handleSelectedFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOptionalAttachedFiles([...optionalAttachedFiles, event.target.files]);
  };

  const handleRequiredUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRequiredAttachment(event.target.files);
  };

  return (
    <ReferralsContext.Provider
      value={{
        fetchChoices,
        serviceTypeChoices,
        studentChoices,
        schoolChoices,
        locationChoices,

        fetchReferralStudent,
        referralStudent,

        createReferralStudent,
        updateReferralStudent,
        createReferral,
        schoolUserCreateReferral,
        referralSubmitting,
        formErrors,

        serviceId,
        requiredAttachment,
        optionalAttachedFiles,

        handleRequiredUpload,
        handleSelectedFile,
      }}
    >
      {children}
    </ReferralsContext.Provider>
  );
};

export { ReferralsContextProvider, ReferralsContextConsumer, ReferralsContext };
