import React from 'react';
import AppointmentsList from '../../appointments/components/AppointmentsList';
import { Service } from '../model';
import { serviceFormStore, serviceRecordStore } from '../../shared/singletons';
import { useStoreObservable } from '../../shared/state/useStoreObservable.hook';
import { appointmentBaseStore } from '../../shared/singletons';
import { FilterProps } from '../../shared/components/FilterBar';
import { allAppointmentStatusChoices } from '../../appointments/constants';

type ServiceAppointmentsTabProps = {
  service: Service;
  serviceLoading: boolean;
};

function ServiceAppointmentsTab(props: ServiceAppointmentsTabProps) {
  const { service, serviceLoading } = props;

  const { allOwnerChoices } = useStoreObservable(appointmentBaseStore);

  const handleAppointmentSort = (sort_params: string) => {
    const filterParams = serviceRecordStore.formattedFilterParams();
    serviceFormStore.fetchServiceAppointments(service.student_id, service.id, `${filterParams}&${sort_params}`);
  };

  const appointmentFilters: FilterProps[] = [
    {
      selector: 'appointment_ransack[original_date_gteq]',
      label: 'Original Date From',
      type: 'date',
      advanced: true,
    },
    {
      selector: 'appointment_ransack[original_date_lteq]',
      label: 'Original Date To',
      type: 'date',
      advanced: true,
    },
    {
      selector: 'appointment_ransack[status_eq]',
      label: 'Status',
      type: 'text',
      options: allAppointmentStatusChoices,
      multiple: false,
    },
    {
      selector: 'appointment_ransack[start_time_gteq]',
      label: 'Date From',
      type: 'date',
    },
    {
      selector: 'appointment_ransack[start_time_lteq]',
      label: 'Date To',
      type: 'date',
    },
    {
      selector: 'appointment_ransack[user_id_eq]',
      label: 'Owner',
      type: 'text',
      options: allOwnerChoices,
      advanced: true,
    },
    {
      selector: 'appointment_ransack[blackout_eq]',
      label: 'School Closing',
      type: 'text',
      advanced: true,
      options: [
        { label: 'Open', value: 'open' },
        { label: 'Conflict', value: 'black' },
      ],
    },
  ];

  return (
    <AppointmentsList
      records={service?.appointments}
      recordsLoading={serviceLoading}
      totalRecords={service?.total_appointments_count}
      paginationServer
      onPageChange={(page) =>
        serviceFormStore.fetchServiceAppointments(service.student_id, service.id, undefined, page)
      }
      onSort={handleAppointmentSort}
      showFilterBar
      filters={appointmentFilters}
      onFilter={() => serviceRecordStore.fetchRecord(service.student_id, service.id)}
      filterStore={serviceRecordStore}
    />
  );
}

export default ServiceAppointmentsTab;
