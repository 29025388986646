import React from 'react';
import { Box, Card, CardContent, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import DataTable from '../../../shared/components/DataTable';
import { Appointment } from '../../model';
import AppointmentCommentsList from '../AppointmentCommentsList';
import { baseColumns, readOnlyStatusColumn, serviceProviderAppointmentColumns } from '../../constants';

// TODO: Separate the two different groups of appointments in the backend
// This approach causes inaccurate counts in the number of appointments shown in the bottom margin

interface AccountantAppointmentsListProps {
  records: Appointment[];
  recordsLoading: boolean;
  totalRecords?: number;
  handlePageChange: (page: number) => void;
  handleColumnSort: (column: Record<string, string>, sortDirection: string) => Promise<void>;
}

const ServiceProviderAppointmentsList: React.FC<AccountantAppointmentsListProps> = (props) => {
  const {
    records = [],
    recordsLoading = false,
    totalRecords = records?.length || 0,
    handlePageChange,
    handleColumnSort,
  } = props;

  const history = useHistory();

  const ExpandedCommentsList = ({ data }) => <AppointmentCommentsList mostRecentComment={data.most_recent_comment} />;

  return (
    <Box>
      <Card>
        <CardContent>
          <Typography variant="h2" component="h2">
            Scheduled & Needs Rescheduled Appointments
          </Typography>
          <DataTable
            columns={serviceProviderAppointmentColumns}
            noHeader
            data={records.filter((appointment) =>
              ['incomplete', 'needs_rescheduled', 'ticket'].includes(appointment.status),
            )}
            progressPending={recordsLoading}
            striped
            onRowClicked={(row: Appointment) =>
              history.push(`/students/${row.student_id}/appointments/${row.id}/session_notes/new`)
            }
            highlightOnHover
            pointerOnHover
            pagination
            paginationServer
            paginationPerPage={25}
            paginationRowsPerPageOptions={[25]}
            paginationTotalRows={totalRecords}
            onChangePage={handlePageChange}
            paginationResetDefaultPage={false}
            expandableRows
            expandableRowExpanded={(appointment: Appointment) => appointment.most_recent_comment !== null}
            expandableRowsComponent={<ExpandedCommentsList data />}
            sortServer
            onSort={handleColumnSort}
          />
        </CardContent>
      </Card>
      <br />
      <Card>
        <CardContent>
          <Typography variant="h2" component="h2">
            Submitted Appointments
          </Typography>
          <DataTable
            columns={[...baseColumns, readOnlyStatusColumn]}
            noHeader
            data={records.filter(
              (appointment) => !['incomplete', 'needs_rescheduled', 'ticket'].includes(appointment.status),
            )}
            progressPending={recordsLoading}
            striped
            highlightOnHover
            pointerOnHover
            onRowClicked={(row: Appointment) =>
              history.push(`/students/${row.student_id}/appointments/${row.id}/session_notes`)
            }
            pagination
            paginationServer
            paginationPerPage={25}
            paginationRowsPerPageOptions={[25]}
            paginationTotalRows={totalRecords}
            onChangePage={handlePageChange}
            paginationResetDefaultPage={false}
            expandableRows
            expandableRowExpanded={(appointment: Appointment) => appointment.most_recent_comment !== null}
            expandableRowsComponent={<ExpandedCommentsList data />}
            sortServer
            onSort={handleColumnSort}
          />
        </CardContent>
      </Card>
    </Box>
  );
};

export default ServiceProviderAppointmentsList;
