import React, { FunctionComponent } from 'react';
import { Card, CardContent, Grid, Typography } from '@material-ui/core';
import InformationField from '../../shared/components/InformationField';
import ReviewField from '../../shared/components/ReviewField';
import LoadingSpinner from '../../shared/components/LoadingSpinner';
import { AppointmentNote } from '../model';

interface AppointmentNoteDetailsProps {
  appointmentNote: AppointmentNote[];
  isLoading: boolean;
}

const AppointmentNoteDetails: FunctionComponent<AppointmentNoteDetailsProps> = (props) => {
  const { appointmentNote, isLoading } = props;

  return isLoading ? (
    <LoadingSpinner />
  ) : (
    <Card>
      <CardContent>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Grid container spacing={8} direction="row">
              <Grid item>
                <Typography variant="h2" component="h2">
                  Appointment Note
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={3} direction="row">
              <Grid item>
                <ReviewField label="Date Completed" value={appointmentNote?.[0]?.appointment_date?.toString()} />
              </Grid>
              <Grid item>
                <ReviewField label="Created by" value={appointmentNote?.[0]?.created_by} />
              </Grid>
              <Grid item>
                <ReviewField label="Status" value={appointmentNote?.[0]?.status} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              {appointmentNote?.map((note) => (
                <div key={note.id}>
                  {Object.keys(JSON.parse(note.content)).map((key) => (
                    <Grid item xs={12}>
                      <InformationField label={key} value={JSON.parse(note.content)[key]} capitalize />
                    </Grid>
                  ))}
                  <hr />
                </div>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default AppointmentNoteDetails;
