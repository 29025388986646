import React, { FunctionComponent, Dispatch, SetStateAction } from 'react';
import { useHistory } from 'react-router-dom';
import Badge from '@mui/material/Badge';
import { Grid, Box, Button, Typography, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import { parse } from 'date-fns';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import WarningIcon from '@mui/icons-material/Report';
import InformationField from '../../shared/components/InformationField';
import { axios, appointmentBaseStore, appointmentRecordStore } from '../../shared/singletons';
import { useStoreObservable } from '../../shared/state/useStoreObservable.hook';
import TextInput from '../../shared/components/form/TextInput';
import { AppointmentsContext } from '../contexts/AppointmentsContext';

interface RescheduleAppointmentPreviewProps {
  setShowPreview: Dispatch<SetStateAction<boolean>>;
}

const RescheduleAppointmentPreview: FunctionComponent<RescheduleAppointmentPreviewProps> = (props) => {
  const history = useHistory();

  const [appointmentFetched, setAppointmentFetched] = React.useState(false);
  const [appointments, setAppointments] = React.useState(true);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [erroredAppointments, setErroredAppointments] = React.useState([]);
  const [updatedAppointments, setUpdatedAppointments] = React.useState([]);
  const [endOfSchoolYear, setEndOfSchoolYear] = React.useState('');

  const { appointment } = useStoreObservable(appointmentRecordStore);
  const { isLoadingChoices } = useStoreObservable(appointmentBaseStore);
  const { appointmentFormValues } = React.useContext(AppointmentsContext);

  const handleDateChange = (index: number, date: string) => {
    const updatedErroredAppointments = [...erroredAppointments];
    updatedErroredAppointments[index].new_date = date;
    setErroredAppointments(updatedErroredAppointments);

    // Update the list of changed appointments
    const updatedAppointmentList = [...updatedAppointments];
    const existingIndex = updatedAppointmentList.findIndex((app) => app.id === updatedErroredAppointments[index].id);

    if (existingIndex > -1) {
      updatedAppointmentList[existingIndex].new_date = date;
    } else {
      updatedAppointmentList.push({ id: updatedErroredAppointments[index].id, new_date: date });
    }

    setUpdatedAppointments(updatedAppointmentList);
  };

  const isDatePastEndOfSchoolYear = (date: string) => {
    console.log(`endOfSchoolYear ${endOfSchoolYear}`);
    const selectedDate = new Date(date);
    const endOfSchoolYearDate = new Date(endOfSchoolYear);
    return selectedDate > endOfSchoolYearDate;
  };

  React.useEffect(() => {
    if (!appointmentFetched) {
      setAppointmentFetched(true);
      axios
        .post(`bulk_appointments.json`, {
          id: appointment.id,
          appointment: appointmentFormValues,
        })
        .then((result) => {
          if (result.data.error) {
            setErrorMessage(result.data.error);
            setErroredAppointments(result.data.errored_appointments || []);
            setEndOfSchoolYear(result.data.end_of_school_year);
            const appointments = result.data.result;
            // Convert appointments data to a format compatible with fullcalendar
            const formattedAppointments = appointments.map((appointment: any) => ({
              title: `${appointment.new_start_time}`,
              start: parse(appointment.new_start_time, 'EEEE, MM-dd-yyyy hh:mm aa', new Date()),
              end: parse(appointment.new_end_time, 'EEEE, MM-dd-yyyy hh:mm aa', new Date()),
              extendedProps: {
                old_start_time: parse(appointment.old_start_time, 'EEEE, MM-dd-yyyy hh:mm aa', new Date()),
                old_end_time: parse(appointment.old_end_time, 'EEEE, MM-dd-yyyy hh:mm aa', new Date()),
              },
            }));

            setAppointments(formattedAppointments);
          } else {
            const appointments = result.data.result;
            // Convert appointments data to a format compatible with fullcalendar
            const formattedAppointments = appointments.map((appointment: any) => ({
              title: `${appointment.new_start_time}`,
              start: parse(appointment.new_start_time, 'EEEE, MM-dd-yyyy hh:mm aa', new Date()),
              end: parse(appointment.new_end_time, 'EEEE, MM-dd-yyyy hh:mm aa', new Date()),
              extendedProps: {
                old_start_time: parse(appointment.old_start_time, 'EEEE, MM-dd-yyyy hh:mm aa', new Date()),
                old_end_time: parse(appointment.old_end_time, 'EEEE, MM-dd-yyyy hh:mm aa', new Date()),
              },
            }));

            setAppointments(formattedAppointments);
          }
        });
    }
  }, [appointmentFetched, appointmentFormValues, appointment.id]);

  const submitUpdate = async () => {
    await axios
      .put<string, any>(`bulk_appointments/${appointment.id}.json`, {
        appointment: appointmentFormValues,
        updated_appointments: updatedAppointments,
        headers: { 'Content-Type': 'application/json' },
      })
      .then(() => {
        history.push(`/students/${appointment.student_id}/services/${appointment.referral_service_id}/appointments`);
      })
      .catch(() => {
        setErrorMessage('Something went wrong. Please reload and try again.');
      });
  };

  const buildErrorMessage = (newDate) => {
    const formattedDate = new Date(newDate);

    if (formattedDate.getDay() > 4) {
      return 'is on a weekend';
    } else if (isDatePastEndOfSchoolYear(newDate)) {
      return 'is past end of school year';
    } else {
      return '';
    }
  };

  const areAllAppointmentsValid = () => {
    return erroredAppointments.every((appointment) => {
      const errorMessage = buildErrorMessage(appointment?.new_date || appointment?.new_start_form_date);
      return errorMessage === '';
    });
  };

  return isLoadingChoices ? (
    <Typography>Loading...</Typography>
  ) : (
    <Box p={2}>
      <Typography variant="h2" style={{ marginTop: 10 }}>
        Preview Appointment Changes
      </Typography>
      <Grid container>
        <Grid item xs={2}></Grid>
        <Grid item xs={8}>
          <FullCalendar
            initialDate={erroredAppointments.length > 0 ? '2024-09-01' : '2025-06-01'}
            plugins={[dayGridPlugin]}
            initialView="dayGridMonth"
            events={appointments as any}
            headerToolbar={{
              left: 'prev,next today',
              center: 'title',
              right: 'dayGridMonth,timeGridWeek,timeGridDay',
            }}
          />
        </Grid>
      </Grid>
      <br />
      {erroredAppointments.length > 0 && (
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Grid container>
              <Grid item xs={12}>
                <Box display="flex" alignItems="center">
                  <Badge color="error">
                    <WarningIcon color="error" />
                  </Badge>
                  <Typography variant="h3" component="h3" style={{ marginLeft: 10 }}>
                    {erroredAppointments.length} Schedule Adjustments Required
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12}>
                {errorMessage.length > 0 && <p style={{ color: 'red' }}>{errorMessage}</p>}
              </Grid>
            </Grid>
          </AccordionSummary>
          <AccordionDetails>
            <Box style={{ width: '100%' }}>
              <Typography variant="h6">Resolve Errored Appointments</Typography>
              {erroredAppointments.map((appointment, index) => (
                <Box key={appointment.id} mb={2}>
                  <Grid container spacing={3}>
                    <Grid item>
                      <InformationField label="Proposed Rescheduled Date" value={appointment.new_start_date} />
                    </Grid>
                    <Grid item md={4}>
                      <TextInput
                        value={appointment?.new_date || appointment?.new_start_form_date}
                        type="date"
                        shrinkLabel
                        label={`Start Date`}
                        errorMessage={buildErrorMessage(appointment?.new_date || appointment?.new_start_form_date)}
                        valueChanged={(value: string) => handleDateChange(index, value)}
                      />
                    </Grid>
                  </Grid>
                </Box>
              ))}
            </Box>
          </AccordionDetails>
        </Accordion>
      )}
      <br />
      <Button variant="contained" color="primary" onClick={submitUpdate} disabled={!areAllAppointmentsValid()}>
        Apply Update
      </Button>
      <Button variant="contained" color="default" onClick={() => window.location.reload()} style={{ marginLeft: 10 }}>
        Back to Scheduling Form
      </Button>

      <br />
    </Box>
  );
};

export default RescheduleAppointmentPreview;
