import React from 'react';
import { studentRecordStore } from '../../shared/singletons';
import { Student } from '../model';
import AppointmentsList from '../../appointments/components/AppointmentsList';
import { FilterProps } from '../../shared/components/FilterBar';
import { useStoreObservable } from '../../shared/state/useStoreObservable.hook';
import { appointmentBaseStore } from '../../shared/singletons';
import { allAppointmentStatusChoices } from '../../appointments/constants';

type StudentAppointmentsTabProps = {
  student: Student;
  appointmentsLoading: boolean;
};

function StudentAppointmentsTab(props: StudentAppointmentsTabProps) {
  const { student, appointmentsLoading } = props;

  const { allOwnerChoices } = useStoreObservable(appointmentBaseStore);

  const appointmentFilters: FilterProps[] = [
    {
      selector: 'appointment_ransack[original_date_gteq]',
      label: 'Original Date From',
      type: 'date',
      advanced: true,
    },
    {
      selector: 'appointment_ransack[original_date_lteq]',
      label: 'Original Date To',
      type: 'date',
      advanced: true,
    },
    {
      selector: 'appointment_ransack[status_eq]',
      label: 'Status',
      type: 'text',
      options: allAppointmentStatusChoices,
      advanced: false,
    },
    {
      selector: 'appointment_ransack[start_time_gteq]',
      label: 'Date From',
      type: 'date',
    },
    {
      selector: 'appointment_ransack[start_time_lteq]',
      label: 'Date To',
      type: 'date',
      advanced: false,
    },
    {
      selector: 'appointment_ransack[user_id_eq]',
      label: 'Owner',
      type: 'text',
      options: allOwnerChoices,
      advanced: true,
    },
    {
      selector: 'appointment_ransack[blackout_eq]',
      label: 'School Closing',
      type: 'text',
      options: [
        { label: 'Open', value: 'open' },
        { label: 'Conflict', value: 'black' },
      ],
      advanced: true,
    },
  ];

  const handleAppointmentSort = (sort_params: string) => {
    const filterParams = studentRecordStore.formattedFilterParams();
    studentRecordStore.fetchStudentAppointments(student.id, `${filterParams}&${sort_params}`, 1, 25);
  };

  return (
    <AppointmentsList
      records={student?.appointments}
      totalRecords={student?.total_appointments_count}
      recordsLoading={appointmentsLoading}
      paginationServer
      onPageChange={(page) => studentRecordStore.fetchStudentAppointments(student.id, undefined, page, 25)}
      onSort={handleAppointmentSort}
      showFilterBar
      filters={appointmentFilters}
      onFilter={() => studentRecordStore.fetchRecord(student.id)}
      filterStore={studentRecordStore}
    />
  );
}

export default StudentAppointmentsTab;
