import React from 'react';
import Typography from '@material-ui/core/Typography';

interface InformationFieldProps {
  label: string;
  capitalize?: boolean;
  value?: string | number | null;
}

const InformationField: React.FC<InformationFieldProps> = (props) => {
  const { label, value, capitalize = false } = props;

  return (
    <div>
      <Typography variant="subtitle1" component="h6" style={{ textTransform: capitalize ? 'capitalize' : 'inherit' }}>
        {label}
      </Typography>
      <Typography variant="body1" component="p" style={{ whiteSpace: 'break-spaces', wordBreak: 'break-word' }}>
        {value}
      </Typography>
    </div>
  );
};

export default InformationField;
