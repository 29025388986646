import React, { FunctionComponent, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import AppointmentsList from '../components/AppointmentsList';
import { useStoreObservable } from '../../shared/state/useStoreObservable.hook';
import { AppointmentRecordState } from '../appointmentRecord.store';
import { appointmentRecordStore, appointmentBaseStore } from '../../shared/singletons';
import { AppointmentsContext } from '../contexts/AppointmentsContext';

export type AppointmentsListPageProps = Record<string, unknown>;

const AppointmentsListPage: FunctionComponent<AppointmentsListPageProps> = () => {
  const appointmentState = useStoreObservable<AppointmentRecordState>(appointmentRecordStore);
  const { filteredRecords, records, recordsLoading, totalRecords } = appointmentState;
  const { fetchDownloadableAppointments } = React.useContext(AppointmentsContext);

  const [downloadableAppointmentsFetched, setDownloadableAppointmentsFetched] = React.useState(false);

  const query = new URLSearchParams(useLocation().search);
  const filterParams = query.get('filters')?.split('__')?.join('&');

  useEffect(() => {
    appointmentRecordStore.fetchRecords(filterParams);
  }, [filterParams]);

  useEffect(() => {
    appointmentBaseStore.fetchChoices();
  }, []);

  useEffect(() => {
    if (!downloadableAppointmentsFetched) {
      setDownloadableAppointmentsFetched(true);
      fetchDownloadableAppointments(appointmentRecordStore.formattedFilterParams());
    }
  }, [downloadableAppointmentsFetched, fetchDownloadableAppointments]);

  return (
    <AppointmentsList
      records={filteredRecords || records}
      recordsLoading={recordsLoading}
      showFilterBar
      totalRecords={totalRecords}
      selectableRows
      showBulkActions
    />
  );
};

export default AppointmentsListPage;
